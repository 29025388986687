
import { defineComponent } from 'vue'
import { NCollapseTransition, NAlert } from 'naive-ui'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: false,
    },
  },
  components: {
    NCollapseTransition,
    NAlert,
  },
  data: () => ({
    show: false,
  }),
})
