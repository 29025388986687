
import { defineComponent, computed } from 'vue'
import { NModal } from 'naive-ui'
import { useStore } from '@/store'
import { getDisplayName } from '@/utils'

export default defineComponent({
  components: { NModal },
  setup: () => {
    const store = useStore()

    return {
      show: computed(() => store.state.wallet.switchPrompt),
    }
  },
  methods: {
    getDisplayName,
  },
})
