/**
 * Wallet Module contains most information that comes from a user's wallet
 * This is also a good module to look at for how to write a Vuex module
 */
import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { providers, BigNumber } from 'ethers'
import Web3 from 'web3'
import analytics from '@/services/analytics'
import { RootState } from '@/store'
import * as types from '@/store/mutation-types'
import { isProduction, networks } from '@/config'
import { getNetworkByChainID } from '@/utils'
import { NetworkName } from '@/config/types'
import Web3Modal from 'web3modal'
import WalletConnectProvider from '@walletconnect/web3-provider'

let connection: any // instance of web3Modal connection
let web3: any // instance of ethers web3Provider

// defined from docs here: https://docs.metamask.io/guide/ethereum-provider.html#errors
interface ProviderRpcError extends Error {
  message: string
  code: number
  data?: unknown
}

export interface WalletState {
  connected: boolean
  allowed: boolean
  network: NetworkName | ''
  address: string
  switchPrompt: string
}

const state = (): WalletState => ({
  connected: false,
  allowed: false,
  network: '',
  address: localStorage.getItem('wallet_address') || '',
  switchPrompt: '',
})

const mutations = <MutationTree<WalletState>>{
  [types.SET_WALLET_CONNECTION](state: WalletState, connected: boolean) {
    console.log('{dispatch} set wallet connection: ', connected)
    state.connected = connected
  },

  [types.SET_WALLET_ALLOWED](state: WalletState, allowed: boolean) {
    console.log('{dispatch} set wallet allowed: ', allowed)
    state.allowed = allowed
  },

  [types.SET_WALLET_NETWORK](state: WalletState, network: NetworkName) {
    console.log('{dispatch} set wallet network: ', network)
    state.network = network
  },

  [types.SET_WALLET_ADDRESS](state: WalletState, address: string) {
    console.log('{dispatch} set wallet address: ', address)
    state.address = address
    localStorage.setItem('wallet_address', address)
  },
  [types.SHOW_SWITCH_PROMPT](state: WalletState, show: string) {
    console.log('{dispatch} set switch network prompt: ', show)
    state.switchPrompt = show
  },
}

const actions = <ActionTree<WalletState, RootState>>{
  async connectWallet({ dispatch, commit, state }) {
    console.log('connecting wallet')

    // check if already connected
    if (state.connected) {
      console.log('already connected to wallet')
      return
    }

    // configure non-infura rpcs
    const {
      VUE_APP_INFURA_KEY,
      VUE_APP_MOONBEAM_RPC,
      VUE_APP_EVMOS_RPC,
      VUE_APP_EVMOS_TESTNET_RPC,
    } = process.env
    const mainnetRpcs = {
      1284: VUE_APP_MOONBEAM_RPC,
      9001: VUE_APP_EVMOS_RPC,
    }
    const testnetRpcs = {
      9000: VUE_APP_EVMOS_TESTNET_RPC,
    }
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          rpc: isProduction ? mainnetRpcs : testnetRpcs,
          infuraId: VUE_APP_INFURA_KEY, // required
        },
        // display: {
        //   description: 'Supported: LedgerLive',
        // },
      },
    }

    const web3Modal = new Web3Modal({
      providerOptions, // required
      cacheProvider: false,
      theme: {
        background: '#2F2F2F',
        main: '#FFFFFF',
        secondary: 'rgba(255, 255, 255, 0.7)',
        border: 'rgba(255, 255, 255, 0.14)',
        hover: 'rgba(255, 255, 255, 0.05)',
      },
    })

    try {
      connection = await web3Modal.connect()
    } catch (err: unknown) {
      // NOTE: just swallow this error, don't need to
      // alert sentry if the modal was closed by the user
      if ((err as any).message === 'Modal closed by user') {
        return
      }
      throw err
    }
    web3 = new Web3(connection)
    const provider = new providers.Web3Provider(connection, 'any')
    const signer = provider.getSigner()

    console.log('connection', connection)
    console.log('signer', signer)

    // listen to events
    connection.on('accountsChanged', async () => {
      if (connection.isMetaMask) {
        location.reload()
        return
      }
      await dispatch('checkAllowed')
    })
    connection.on('chainChanged', async (chainId: number) => {
      console.log('network change', chainId)
      // get name of network and set in store
      const id = BigNumber.from(chainId).toNumber()
      const network = getNetworkByChainID(id)
      if (network) {
        // network supported, setting wallet network
        await dispatch('setWalletNetwork', network.name)
      } else {
        // network not supported, clearing network
        await dispatch('setWalletNetwork', '')
      }
    })

    // get and set address
    const address = await signer.getAddress()
    dispatch('setWalletAddress', address)
    analytics.identify(address)
    analytics.track('Connected Wallet', { walletAddress: address })

    // set network, if supported
    const { chainId } = connection
    const chainIdNum = BigNumber.from(chainId).toNumber()
    const network = getNetworkByChainID(chainIdNum)
    if (network) {
      dispatch('setWalletNetwork', network.name)
      dispatch('setOriginNetwork', network.name)
    } else {
      console.log('network not supported')
    }

    await dispatch('checkAllowed')
    commit(types.SET_WALLET_CONNECTION, true)
  },

  async checkAllowed({ commit, state, rootGetters }) {
    if (state.address) {
      const allowed = await rootGetters.isAllowed(state.address)
      analytics.track('User on allow list', {
        walletAddress: state.address,
        isAllowed: allowed,
      })
      commit(types.SET_WALLET_ALLOWED, allowed)
    }
  },

  setWalletAddress({ commit }, address: string) {
    commit(types.SET_WALLET_ADDRESS, address)
  },

  // when user changes network in their wallet
  setWalletNetwork({ commit }, networkName: string) {
    commit(types.SET_WALLET_NETWORK, networkName)
  },

  async switchNetwork({ commit, dispatch, state }, networkName: string) {
    console.log('set wallet network', networkName)
    if (!state.connected) {
      await dispatch('connectWallet')
    }

    // if provider does not exist yet, no need to handle right now
    if (!web3 || !connection) return

    const network = networks[networkName]
    const stringId = network.chainID.toString(16)
    const hexChainId = '0x' + stringId

    // if wallet is already on correct chain, return
    if (connection.chainId == stringId) {
      // set the wallet network in case it is null when selecting an unavailable network
      commit(types.SET_WALLET_NETWORK, networkName)
      return
    }

    // switch chains
    try {
      console.log('isMetaMask', connection.isMetaMask)
      if (!connection.isMetaMask) {
        commit(types.SHOW_SWITCH_PROMPT, networkName)
      }
      await connection.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainId }],
      })
      commit(types.SET_WALLET_NETWORK, networkName)
      commit(types.SHOW_SWITCH_PROMPT, '')
    } catch (switchError: unknown) {
      // This error code indicates that the chain has not been added to their wallet.
      if ((switchError as ProviderRpcError).code === 4902) {
        await connection.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: hexChainId,
              rpcUrls: [network.rpcUrl],
              chainName: network.name,
            },
          ],
        })
        commit(types.SET_WALLET_NETWORK, networkName)
      } else {
        throw switchError
      }
      commit(types.SHOW_SWITCH_PROMPT, '')
    }
  },
}

const getters = <GetterTree<WalletState, RootState>>{
  getSigner: () => () => {
    const provider = new providers.Web3Provider(connection, 'any')
    return provider.getSigner()
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
