
import { defineComponent, computed } from 'vue'
import { NText, NDivider, useNotification } from 'naive-ui'
import { useStore } from '@/store'
import { getTxHistory, getTxHistoryCount, IndexerTx } from '@/utils/nomadAPI'

import Transaction from './columns/transaction.vue'
import Amount from './columns/amount.vue'
import FromTo from './columns/fromto.vue'
import Paginate from '@/components/Paginate.vue'
import ConnectWallet from '@/components/ConnectWallet.vue'

type ComponentData = {
  history: Array<unknown>
  addrParam: string
}

export default defineComponent({
  components: {
    NText,
    NDivider,
    Transaction,
    Amount,
    FromTo,
    Paginate,
    ConnectWallet,
  },

  data() {
    return {
      history: undefined as Array<IndexerTx> | undefined,
      addrParam: '',
    } as ComponentData
  },

  setup: () => {
    const store = useStore()
    const notification = useNotification()

    return {
      size: 7,
      txCount: undefined as number | undefined,
      domains: store.getters.getDomains(),
      walletConnected: computed(() => store.state.wallet.connected),
      address: computed(() => store.state.wallet.address),
      notification,
      store,
    }
  },

  async mounted() {
    this.addrParam = this.$route.params.address as string
    this.txCount = await getTxHistoryCount(this.addrParam || this.address)
    this.getHistory()
  },

  methods: {
    async getHistory(page = 1) {
      console.log('page:', page)
      if (!this.address && !this.addrParam) return
      this.history = await getTxHistory(
        this.addrParam || this.address,
        page,
        this.size
      )
    },
    toTx(tx: any) {
      this.$router.push(`/tx/${tx.message_hash}`)
    },
    async toBridge() {
      if (this.walletConnected) {
        this.$router.push('/bridge')
      } else {
        this.notification.info({
          title: 'Connect Wallet',
          description: 'You must connect your wallet before bridging',
          duration: 3000,
        })
      }
    },
  },

  watch: {
    async address(newAddress, oldAddress) {
      if (newAddress !== oldAddress) {
        this.history = []
        this.txCount = await getTxHistoryCount(this.address)
        await this.getHistory()
      }
    },
  },
})
