import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "uppercase mb-1" }
const _hoisted_2 = { class: "opacity-80 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert_circle_outline = _resolveComponent("alert-circle-outline")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_close_outline = _resolveComponent("close-outline")!
  const _component_n_collapse_transition = _resolveComponent("n-collapse-transition")!

  return (_openBlock(), _createBlock(_component_n_collapse_transition, { show: _ctx.show }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-row rounded-xl bg-[#5185d0] p-4 mb-4 max-w-xl m-auto", [_ctx.warning ? 'bg-[#ffb11f] text-black' : 'bg-[#5185d0]']])
      }, [
        _createVNode(_component_n_icon, {
          size: "25",
          class: "mr-2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_alert_circle_outline)
          ]),
          _: 1
        }),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]),
        (_ctx.closeable)
          ? (_openBlock(), _createBlock(_component_n_icon, {
              key: 0,
              class: "cursor-pointer",
              size: "20",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_close_outline)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ], 2)
    ]),
    _: 3
  }, 8, ["show"]))
}