
import { defineComponent } from 'vue'
import { NConfigProvider, NNotificationProvider, darkTheme } from 'naive-ui'
import ErrorBoundary from '@/components/ErrorBoundary.vue'
import App from './App.vue'

const themeOverrides = {
  common: {
    primaryColor: '#FFF',
    secondaryColor: '#E24084',
    secondaryColorHover: '#B14EA6',
  },
}

export default defineComponent({
  components: {
    NConfigProvider,
    NNotificationProvider,
    ErrorBoundary,
    App,
  },
  data: () => ({
    darkTheme,
    themeOverrides,
  }),
})
