import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { BigNumber, ContractReceipt, utils } from 'ethers'
import { TokenIdentifier, TransferMessage } from '@nomad-xyz/sdk-bridge'
import { NftInfo } from '@nomad-xyz/sdk-bridge/dist/BridgeContracts'
import analytics from '@/services/analytics'
import { RootState } from '@/store'
import * as types from '@/store/mutation-types'
import { networks, AFFECTED_NETWORK } from '@/config'
import { getNativeBalance, getERC20Balance } from '@/utils/balance'
import { getNetworkByDomainID } from '@/utils'
import { NetworkMetadata, TokenMetadata } from '@/config/types'
import { bridgeMessageFromMessageHash } from '@/utils/nomadAPI'
const nomadSDK = await import('@nomad-xyz/sdk-bridge')
import { nomad } from '@/config/utils'

export type TXData = {
  network: number
  hash: string
}

export type TokenBalance = {
  token: string // key
  balance: BigNumber
}

export interface SendData {
  originNetwork: string | number
  destNetwork: string | number
  asset: TokenIdentifier
  amnt: number
  recipient: string
  gasLimit?: number
}

export interface SDKState {
  balance: BigNumber | null
  balances: { [key: string]: BigNumber }
  sending: boolean
  processing: boolean
  blacklist: Set<number>
}

const state: SDKState = {
  balance: null,
  balances: {},
  sending: false,
  processing: false,
  blacklist: new Set(),
}

const mutations = <MutationTree<SDKState>>{
  [types.SET_BALANCE](state: SDKState, balance: BigNumber | null) {
    console.log('{dispatch} set balance: ', balance)
    state.balance = balance
  },

  [types.SET_ERC20_BALANCES](state: SDKState, payload: TokenBalance[]) {
    payload.forEach((b) => {
      if (b && b.balance && !b.balance.isZero()) {
        state.balances[b.token] = b.balance
      }
    })
  },

  [types.SET_ERC20_BALANCE](state: SDKState, payload: TokenBalance) {
    const { token, balance } = payload
    console.log(`{dispatch} set ${token} balance: ${balance}`)
    state.balances[token] = balance
  },

  [types.SET_SENDING](state: SDKState, sending: boolean) {
    console.log('{dispatch} transaction send in progress: ', sending)
    state.sending = sending
  },

  [types.SET_PROCESSING](state: SDKState, processing: boolean) {
    console.log('{dispatch} transaction processing in progress: ', processing)
    state.processing = processing
  },

  [types.SET_BLACKLIST](state: SDKState, blacklist: Set<number>) {
    console.log('{dispatch} set blacklist: ', blacklist)
    state.blacklist = blacklist
  },
}

const actions = <ActionTree<SDKState, RootState>>{
  async checkFailedHomes({ commit }) {
    if (nomad) {
      await nomad.checkHomes(Object.keys(networks))
      const blacklist = nomad.blacklist()
      console.log('blacklist', blacklist)
      commit(types.SET_BLACKLIST, blacklist)
    }
  },

  async getBalances({ rootState, rootGetters, commit }) {
    console.log('getting token balances', getERC20Balance)
    const networkName = rootState.userInput.originNetwork
    const address = rootState.wallet.address
    const tokens: TokenMetadata[] = rootGetters.availableTokens

    const balancePromises: Promise<TokenBalance | undefined>[] = tokens.map(
      (token) => {
        return getERC20Balance(nomad, networkName, token, address)
      }
    )
    const balances = await Promise.all(balancePromises)
    console.log('BALANCES', balances)
    commit(types.SET_ERC20_BALANCES, balances)
  },

  async getNativeBalance({
    rootState,
    commit,
  }): Promise<BigNumber | undefined> {
    // get current network domain
    const networkName = rootState.userInput.originNetwork
    const address = rootState.wallet.address

    try {
      const balance = await getNativeBalance(nomad, networkName, address)
      commit(types.SET_BALANCE, balance)
      return balance
    } catch (e) {
      // there is no balance so it errors
      return
    }
  },

  registerSigner({ rootGetters }, network: NetworkMetadata) {
    console.log('registering signer for ', network.name)
    const networkName = network.name
    const signer = rootGetters.getSigner()

    nomad.clearSigners()
    nomad.missingProviders
      .map((numberStr: string) => parseInt(numberStr))
      .forEach((domain: number) => {
        const network = getNetworkByDomainID(domain)
        nomad.registerRpcProvider(networkName, network.rpcUrl)
      })

    nomad.registerSigner(networkName, signer)
  },

  async send({
    rootState,
    commit,
    dispatch,
  }): Promise<TransferMessage | undefined> {
    console.log('sending...', rootState.userInput)
    commit(types.SET_SENDING, true)

    const { originNetwork, destinationNetwork, token, sendAmount } =
      rootState.userInput
    const { address: walletAddress } = rootState.wallet
    const originDomain = nomad.resolveDomain(originNetwork)
    const destDomain = nomad.resolveDomain(destinationNetwork)
    dispatch('switchNetwork', originNetwork)
    dispatch('registerSigner', networks[originNetwork])

    try {
      const transferMessage = await nomad.send(
        originDomain,
        destDomain,
        token.tokenIdentifier,
        utils.parseUnits(sendAmount.toString(), token.decimals),
        walletAddress,
        false
      )
      console.log('tx sent!!!!!!!!!!!!', transferMessage)
      analytics.track('Sent bridge transfer success', {
        transferMessage: transferMessage.dispatch.args.messageHash,
        walletAddress: rootState.wallet.address,
      })
      commit(types.SET_SENDING, false)
      return transferMessage
    } catch (e: any) {
      analytics.track('Sent bridge transfer failure', {
        walletAddress: rootState.wallet.address,
        error: e.message,
      })
      commit(types.SET_SENDING, false)
      await dispatch('checkFailedHomes')
      throw e
    }
  },

  async processTx({ commit, dispatch }, messageHash: string) {
    commit(types.SET_PROCESSING, true)
    // get transfer message
    let message
    try {
      message = await nomadSDK.BridgeMessage.bridgeFromMessageHash(
        nomad,
        messageHash
      )
    } catch (e) {
      message = await bridgeMessageFromMessageHash(nomad, messageHash)
    }
    if (!message) throw new Error('Cannot fetch message details')

    // switch network and register signer
    const destNetwork = getNetworkByDomainID(message.destination)
    await dispatch('switchNetwork', destNetwork.name)
    await dispatch('registerSigner', destNetwork)

    try {
      const receipt = await message.process()
      console.log('PROCESSED!!!!')
      analytics.track('processed message', {
        message: message.dispatch.args.messageHash,
      })
      commit(types.SET_PROCESSING, false)
      return receipt
    } catch (e) {
      commit(types.SET_PROCESSING, false)
      await dispatch('checkFailedHomes')
      throw e
    }
  },

  async recoverFunds(
    { dispatch },
    id: BigNumber
  ): Promise<ContractReceipt | undefined> {
    await dispatch('switchNetwork', AFFECTED_NETWORK)
    await dispatch('registerSigner', networks[AFFECTED_NETWORK])
    return nomad.recover(id)
  },
}

const getters = <GetterTree<SDKState, RootState>>{
  bridgeContext: () => () => nomad,
  activeNetworks: (state: SDKState) => () => {
    return Object.keys(networks)
      .filter((n) => !state.blacklist.has(networks[n].domainID))
      .map((n) => networks[n])
  },
  blacklist: (state: SDKState) => () => state.blacklist,
  getGasPrice: () => async (network: string | number) => {
    const provider = nomad.getProvider(network)
    const gasPrice = await provider?.getGasPrice()
    return gasPrice
  },

  resolveDomain: () => (network: string) => {
    return nomad.resolveDomain(network)
  },

  resolveDomainName: () => (network: number) => {
    return nomad.resolveDomainName(network)
  },

  resolveRepresentation:
    () => async (network: string, token: TokenIdentifier) => {
      return await nomad.resolveRepresentation(network, token)
    },

  getDomains: () => () => nomad.domainNumbers,

  getNftInfo:
    () =>
    async (id: BigNumber): Promise<NftInfo | undefined> => {
      return await nomad.nftInfo(id)
    },

  isAllowed:
    () =>
    async (address: string): Promise<boolean> => {
      const allowed = await nomad.isAllowed(address)
      console.log('user allowed:', allowed)
      return allowed
    },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
