
import { defineComponent, computed, ref } from 'vue'
import { useStore } from '@/store'
import { BigNumber } from '@ethersproject/bignumber'
import { getUserNfts, ProcessFailure } from '@/utils/nomadAPI'

import NftPreview from '@/components/NFTPreview.vue'
import Paginate from '@/components/Paginate.vue'
import ConnectWallet from '@/components/ConnectWallet.vue'

export default defineComponent({
  components: {
    NftPreview,
    Paginate,
    ConnectWallet,
  },
  setup() {
    const store = useStore()

    return {
      userAddress: computed(() => store.state.wallet.address),
      store,
    }
  },
  data: () => ({
    nfts: [] as ProcessFailure[],
    size: 6,
    pageCount: ref(1000),
  }),
  async mounted() {
    this.getHistory()
  },
  methods: {
    async handleRecover(id: BigNumber) {
      this.$router.push(`/recover/${id.toString()}`)
    },
    async toBridge() {
      this.$router.push('bridge')
    },
    async getHistory(page = 1) {
      if (!this.userAddress) return

      const nfts = await getUserNfts(this.userAddress, page, this.size)
      if (nfts.length) {
        this.nfts = nfts
        if (nfts.length < this.size) {
          this.pageCount = page
        }
      } else {
        this.pageCount = page
      }
    },
  },

  watch: {
    async userAddress(newAddress, oldAddress) {
      if (newAddress !== oldAddress) {
        this.nfts = []
        this.pageCount = 1000
        await this.getHistory()
      }
    },
  },
})
