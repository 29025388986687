
import { computed, defineComponent } from 'vue'
import analytics from '@/services/analytics'
import { useStore } from '@/store'
import { truncateAddr } from '@/utils'
import { COINLIST_VERIFY_LINK } from '@/config'
import NomadButton from '@/components/Button.vue'
import ConnectWallet from '@/components/ConnectWallet.vue'

export default defineComponent({
  components: { NomadButton, ConnectWallet },
  data: () => ({
    address: '',
    allowed: false,
    noAddress: {
      img: require('@/assets/icons/step_wallet.svg'),
      title: 'Connect Wallet',
      description:
        'Check your status with CoinList Verify+. To begin, connect the wallet that holds all of your Nomad assets.',
      button: 'Connect Wallet',
      link: '/bridge',
    },
    allowedText: {
      img: require('@/assets/icons/step_coinlist-check.svg'),
      title: 'Successfully verified',
      description:
        'Good news! You have successfully completed verification through CoinList Verify+. Begin bridging to receive your NFT(s) which can be used to access recovered funds.',
      button: 'Bridge Funds',
      link: '/bridge',
    },
    notAllowedText: {
      img: require('@/assets/icons/step_coinlist.svg'),
      title: 'Verify with CoinList',
      description:
        'Verify now with CoinList Verify+. You must complete this process to bridge back and access recovered funds. If you have already completed this, check back later. It can take up to 24 hours to reflect the change in status',
      button: 'CoinList Verify+',
      link: '',
    },
    truncateAddr,
  }),
  setup() {
    const store = useStore()

    return {
      userAddress: computed(() => store.state.wallet.address),
      store,
    }
  },
  async mounted() {
    this.address = (this.$route.params.address as string) || this.userAddress
    console.log(this.address)
    if (!this.address || this.address.length !== 42) return
    this.allowed = await this.store.getters.isAllowed(this.address)
    console.log(this.allowed)
  },
  methods: {
    toCoinlistVerify() {
      analytics.track('Opened CoinList Verify Link', {
        walletAddress: this.userAddress,
      })
      window.open(COINLIST_VERIFY_LINK)
    },
    toBridge() {
      this.$router.push('/bridge')
    },
  },
  watch: {
    async userAddress() {
      this.address = this.userAddress
      this.allowed = await this.store.getters.isAllowed(this.address)
    },
  },
})
