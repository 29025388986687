import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "origin",
  class: "w-full"
}
const _hoisted_2 = { class: "stepper__title" }
const _hoisted_3 = { class: "stepper__subtitle" }
const _hoisted_4 = { class: "stepper__content" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "flex items-center" }
const _hoisted_7 = { class: "bg-black bg-opacity-50 rounded-lg p-2 mr-2" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "capitalize" }
const _hoisted_10 = { class: "opacity-70" }
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_spin = _resolveComponent("n-spin")!
  const _component_nomad_button = _resolveComponent("nomad-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.subtitle), 1),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.networks, (network) => {
        return (_openBlock(), _createElementBlock("div", {
          key: network.domainID,
          class: _normalizeClass(["stepper__option", {
          'stepper__option--active': network.name === _ctx.originNetwork,
        }]),
          onClick: ($event: any) => (_ctx.switchNetwork(network.name))
        }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("img", {
                src: network.icon,
                class: "h-4 w-4"
              }, null, 8, _hoisted_8)
            ]),
            _createElementVNode("span", _hoisted_9, _toDisplayString(network.name), 1)
          ]),
          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.truncatedAddress), 1)
        ], 10, _hoisted_5))
      }), 128))
    ]),
    _createVNode(_component_nomad_button, {
      primary: "",
      class: "stepper__continue",
      onClick: _ctx.next,
      disabled: !_ctx.valid || _ctx.loading
    }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_n_spin, {
              key: 0,
              size: "tiny",
              stroke: "#000"
            }))
          : (_openBlock(), _createElementBlock("span", _hoisted_11, "Continue"))
      ]),
      _: 1
    }, 8, ["onClick", "disabled"])
  ]))
}