
import { defineComponent, computed, h } from 'vue'
import { BigNumber } from 'ethers'
import { NIcon, NDivider, NSpin, NTooltip, useNotification } from 'naive-ui'
import { InformationCircleOutline } from '@vicons/ionicons5'
import { useStore } from '@/store'
import analytics from '@/services/analytics'
import { getTokenByTokenID, toDecimals } from '@/utils'
import { TokenMetadata } from '@/config/types'
import { AFFECTED_NETWORK, networks, COINLIST_VERIFY_LINK } from '@/config'
import { getNftRecoveries, Recovery } from '@/utils/nomadAPI'

import NftPreview from '@/components/NFTPreview.vue'
import NomadButton from '@/components/Button.vue'
import NotificationLink from '@/components/NotificationLink.vue'
import NotificationError from '@/components/NotificationError.vue'
import History from './History.vue'
import Indicator from '@/components/PulsingIndicator.vue'

export default defineComponent({
  emits: ['recover'],
  components: {
    NIcon,
    NDivider,
    NSpin,
    NTooltip,
    NftPreview,
    NomadButton,
    InformationCircleOutline,
    History,
    Indicator,
  },
  setup: () => {
    const store = useStore()
    const notification = useNotification()
    return {
      connected: computed(() => store.state.wallet.connected),
      allowed: computed(() => store.state.wallet.allowed),
      walletAddress: computed(() => store.state.wallet.address),
      origin: computed(() => store.state.userInput.originNetwork),
      notification,
      store,
    }
  },
  data: () => ({
    id: undefined as BigNumber | undefined,
    originalAmount: '',
    asset: '',
    recovered: '',
    available: '',
    isAllowed: false,
    recovering: false,
    owner: '',
    nftHistory: [] as Recovery[],
  }),
  async mounted() {
    this.id = BigNumber.from(this.$route.params.id as string)
    this.getInfo()
  },
  methods: {
    async getInfo() {
      if (!this.id) return
      const info = await this.store.getters.getNftInfo(this.id)
      this.nftHistory = await getNftRecoveries(this.id)
      this.originalAmount = info._originalAmount
      this.recovered = info._recovered
      this.available = info._recoverable
      this.asset = info._asset
      this.owner = info._holder
      this.isAllowed = info._isAllowed
    },
    async accessFunds() {
      if (this.walletAddress !== this.owner) {
        this.notification.error({
          title: 'Error',
          description: 'Connected wallet must be the owner of the NFT',
          duration: 5000,
        })
        return
      }
      await this.store.dispatch('checkAllowed')
      if (!this.allowed) {
        this.notification.error({
          title: 'Cannot access recovered funds',
          description: 'Please check your status with CoinList Verify+',
        })
        throw new Error(`Access funds failed, user not allowed`)
      }

      console.log(this.id)
      this.recovering = true
      try {
        const receipt = await this.store.dispatch('recoverFunds', this.id)
        console.log(receipt)
        if (receipt) {
          analytics.track('Recover funds success', {
            walletAddress: this.walletAddress,
          })
          this.notification.success({
            title: 'Success',
            content: () =>
              h(NotificationLink, {
                text: 'View transaction on Etherscan',
                linkText: 'View on Etherscan',
                link: `${networks[AFFECTED_NETWORK].blockExplorer}tx/${receipt.transactionHash}`,
              }),
          })
        } else {
          analytics.track('Recover funds failure', {
            walletAddress: this.walletAddress,
          })
          this.notification.error({
            title: 'Transaction failed',
            description: 'Please contact support in our Discord server',
          })
          this.recovering = false
        }
      } catch (e: any) {
        const m = e.message.toLowerCase()
        if (m.includes('user denied') || m.includes('user rejected')) {
          this.recovering = false
          return
        }
        this.notification.warning({
          title: 'Transaction failed',
          content: () =>
            h(NotificationError, {
              text: 'Please contact support in our Discord server',
              error: e as Error,
            }),
        })
        this.recovering = false
        throw e
      }
      this.recovering = false
      this.getInfo()
      setTimeout(async () => {
        if (this.id) {
          this.nftHistory = await getNftRecoveries(this.id)
        }
      }, 3000)
    },
    async connectWallet() {
      try {
        await this.store.dispatch('connectWallet')
      } catch (e: any) {
        const m = e.message.toLowerCase()
        if (m.includes('user rejected')) return
        this.notification.error({
          title: 'Error accessing recovered funds',
          content: () =>
            h(NotificationError, {
              text: 'Please contact support in our Discord server',
              error: e as Error,
            }),
        })
      }
    },
    toCoinList() {
      analytics.track('Opened CoinList Verify Link', {
        walletAddress: this.walletAddress,
      })
      window.open(COINLIST_VERIFY_LINK)
    },
    toDecimals,
  },
  computed: {
    token(): TokenMetadata | undefined {
      if (!this.asset) return
      const tokenId = {
        domain: AFFECTED_NETWORK,
        id: this.asset,
      }
      return getTokenByTokenID(tokenId)
    },
    percentage(): string | undefined {
      if (!this.recovered || !this.available || !this.originalAmount) return
      const totalRecovered = BigNumber.from(this.recovered).add(this.available)
      const div = totalRecovered.mul(100000).div(this.originalAmount).toNumber()
      const percentageWith3Decimals = div / 1000
      return `${percentageWith3Decimals}%`
    },
  },
})
