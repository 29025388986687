
import { defineComponent, h } from 'vue'
import { useNotification } from 'naive-ui'
import { useStore } from '@/store'
import NotificationError from '@/components/NotificationError.vue'
import NomadButton from '@/components/Button.vue'

export default defineComponent({
  emits: ['connected'],
  props: {
    text: {
      type: String,
      required: false,
    },
  },
  components: {
    NomadButton,
  },
  setup: () => {
    const store = useStore()
    const notification = useNotification()
    return {
      notification,
      store,
    }
  },
  methods: {
    async handleConnect() {
      try {
        await this.store.dispatch('connectWallet')
        this.$emit('connected')
      } catch (error: any) {
        const m = error.message.toLowerCase()
        if (m.includes('user rejected')) return
        console.error(error)
        this.notification.error({
          title: 'Could not connect wallet',
          content: () =>
            h(NotificationError, {
              text: 'Please contact support in our Discord server',
              error: error as Error,
            }),
        })
      }
    },
  },
})
