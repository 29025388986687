
import { defineComponent } from 'vue'
import { NCollapseTransition, NIcon } from 'naive-ui'
import { ChevronDown } from '@vicons/ionicons5'

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    error: {
      type: Error,
      required: true,
    },
  },
  components: {
    NCollapseTransition,
    NIcon,
    ChevronDown,
  },
  data: () => ({
    expand: false,
  }),
})
