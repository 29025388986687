import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-429276ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = {
  key: 0,
  class: "badge rounded-md"
}
const _hoisted_4 = {
  key: 0,
  class: "opacity-70 mr-2"
}
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = {
  key: 3,
  class: "ml-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.token && _ctx.token.symbol)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "nft-container",
        style: _normalizeStyle({ width: `${_ctx.size}px`, height: `${_ctx.size}px` })
      }, [
        _createVNode(_Transition, {
          name: _ctx.transition,
          appear: ""
        }, {
          default: _withCtx(() => [
            (_ctx.affected)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  src: 
          _ctx.isProduction
            ? _ctx.token.nftThumbnail
            : require(`@/assets/nft-thumbnails/img-${_ctx.randomIntFromInterval()}.png`)
        ,
                  alt: "nft",
                  class: _normalizeClass(["nft", {
          'cursor-pointer': _ctx.hover,
          'opacity-70': _ctx.pending,
          'fade-in-out': _ctx.fade,
        }])
                }, null, 10, _hoisted_1))
              : (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: _normalizeClass(["asset__bg", { hover: _ctx.hover, pending: _ctx.pending, 'fade-in-out': _ctx.fade }])
                }, [
                  (_openBlock(), _createElementBlock("img", {
                    key: _ctx.token.symbol,
                    src: _ctx.token.icon,
                    alt: _ctx.token.symbol,
                    class: "asset__icon"
                  }, null, 8, _hoisted_2))
                ], 2))
          ]),
          _: 1
        }, 8, ["name"]),
        _createVNode(_Transition, {
          name: _ctx.transition,
          mode: "out-in",
          appear: ""
        }, {
          default: _withCtx(() => [
            (_ctx.asset || _ctx.amount)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_ctx.affected)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_4, "NFT"))
                    : _createCommentVNode("", true),
                  (_ctx.amount)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.assetAmount), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.emDash), 1)),
                  (_ctx.token)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.token.symbol), 1))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["name"])
      ], 4))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "nft-container fade-in-out bg-[#151515]",
        style: _normalizeStyle({ width: `${_ctx.size}px`, height: `${_ctx.size}px` })
      }, null, 4))
}