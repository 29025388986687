
import { defineComponent, computed } from 'vue'
import { NModal, NCard, NCheckbox, useNotification } from 'naive-ui'
import { useStore } from '@/store'
import { termsAPI } from '@/config'
import NomadButton from '@/components/Button.vue'
import Terms from '@/views/TermsOfUse.vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: {
    NModal,
    NCard,
    NCheckbox,
    NomadButton,
    Terms,
  },

  setup: () => {
    const store = useStore()
    const route = useRoute()
    const notification = useNotification()
    return {
      walletAddress: computed(() => store.state.wallet.address),
      hideModal: computed(() =>
        ['TermsOfUse', 'PrivacyPolicy'].includes(route.name as string)
      ),
      notification,
      store,
    }
  },

  data: () => ({
    showTermsModal: false,
    checkbox1: false,
    checkbox2: false,
    alert: '',
  }),

  mounted() {
    this.checkTerms(this.walletAddress)
  },

  methods: {
    async agree() {
      if (!this.scrolledToBottom()) {
        this.alert = 'Please scroll through and read our Terms of Use'
        return
      }
      if (!this.checkbox1 || !this.checkbox2) {
        this.alert =
          'Please check both boxes to agree to the Terms of Use and Privacy Policy'
        return
      }
      const errorText =
        'Disable ad blockers and refresh. Contact support on Discord if this error continues.'
      let ip
      try {
        ip = await fetch('https://api.ipify.org?format=json')
          .then((x) => x.json())
          .then((res) => res.ip)
      } catch (e) {
        this.notification.error({
          title: 'Error agreeing to terms',
          description: errorText,
        })
        throw new Error('Could not fetch ip')
      }
      if (!ip) {
        this.notification.error({
          title: 'Error agreeing to terms',
          description: errorText,
        })
        throw new Error('Could not fetch ip')
      }
      try {
        const response = await fetch(
          `${termsAPI}api/agree/${this.walletAddress}/${ip}`,
          {
            method: 'POST',
          }
        )
        console.log(response)
        setTimeout(() => {
          this.checkTerms(this.walletAddress)
        }, 1000)
      } catch (e) {
        // if db is down, allow user to proceed after agreeing
        this.showTermsModal = false
        throw new Error(`Failed to post to terms database: \n${e}`)
      }
    },
    async checkTerms(addr: string) {
      if (!addr) {
        return (this.showTermsModal = false)
      }

      try {
        const response = await fetch(`${termsAPI}api/agreement/${addr}`)
        if (response.status === 200) {
          console.log('user agreed')
          return (this.showTermsModal = false)
        } else if (response.status === 404) {
          return (this.showTermsModal = true)
        }
      } catch (e) {
        // if db is down, show terms by default
        this.showTermsModal = true
        throw new Error(`Failed to fetch from terms database: \n${e}`)
      }
    },
    scrolledToBottom() {
      const terms = document.getElementById('terms')
      if (!terms) return false
      // Don't include padding on bottom of div (60px)
      return terms.scrollTop > terms.scrollHeight - terms.offsetHeight - 60
    },
  },

  watch: {
    walletAddress(newAddr) {
      this.checkTerms(newAddr)
    },
  },
})
