/*
This is an example snippet - you should consider tailoring it
to your service.
*/
import { nomadAPI } from '@/config'
import { BigNumber } from 'ethers'
import { isProduction } from '@/config'
import { BridgeContext, BridgeMessage } from '@nomad-xyz/sdk-bridge'
const nomadSDK = await import('@nomad-xyz/sdk-bridge')
const prefix = isProduction ? 'production_views_' : 'development_views_'
const suffix = isProduction ? '' : '__staging'

export type IndexerTx = {
  committed_root: string
  destination_and_nonce: BigNumber
  destination_domain_id: number
  destination_domain_name: string
  dispatch_block: number
  dispatch_tx: string
  dispatched_at: string
  id: string
  leaf_index: number
  message: string
  message__action__amount: string
  message__action__details_hash: string
  message__action__to: string
  message__action__type: string
  message__token__domain: string
  message__token__id: string
  message_body: string
  message_hash: string
  message_type: string
  new_root: string
  nonce: number
  old_root: string
  origin_domain_id: number
  origin_domain_name: string
  process_block: number
  process_tx: string
  processed_at: string
  recipient_address: string
  relay_block: number
  relay_chain_id: number
  relay_tx: string
  relayed_at: string
  sender_address: string
  signature: string
  update_block: number
  update_chain_id: number
  update_tx: string
  updated_at: string
  amount: string
}

export type ProcessFailure = {
  id_param: BigNumber
  asset: string
  recipient: string
  amount: BigNumber
}

export type Recovery = {
  id: string
  timestamp: string
  transaction_hash: string
  contract_id: string
  id_param: BigNumber
  asset: string
  recipient: string
  amount: BigNumber
}

export enum TxStatus {
  NONE = 0,
  DISPATCHED,
  UPDATED,
  RELAYED,
  PROCESSED,
}

const bridgeColumns = `
  committed_root
  destination_and_nonce
  destination_domain_id
  destination_domain_name
  dispatch_block
  dispatch_tx
  dispatched_at
  id
  leaf_index
  message
  message__action__amount
  message__action__details_hash
  message__action__to
  message__action__type
  message__token__domain
  message__token__id
  message_body
  message_hash
  message_type
  new_root
  nonce
  old_root
  origin_domain_id
  origin_domain_name
  process_block
  process_tx
  processed_at
  recipient_address
  relay_block
  relay_chain_id
  relay_tx
  relayed_at
  sender_address
  signature
  update_block
  update_chain_id
  update_tx
  updated_at
  amount
  committed_root
  destination_and_nonce
  destination_domain_id
  destination_domain_name
  dispatch_block
  dispatch_tx
  dispatched_at
  id
  leaf_index
  message
  message__action__amount
  message__action__details_hash
  message__action__to
  message__action__type
  message__token__domain
  message__token__id
  message_body
  message_hash
  message_type
  new_root
  nonce
  old_root
  origin_domain_id
  origin_domain_name
  process_block
  process_tx
  processed_at
  recipient_address
  relay_block
  relay_chain_id
  relay_tx
  relayed_at
  sender_address
  signature
  update_block
  update_chain_id
  update_tx
  updated_at
  amount
`

export function getStatus(tx: IndexerTx): TxStatus {
  let state
  switch (true) {
    case !!tx.process_tx:
      state = 4
      break
    case !!tx.relay_tx:
      state = 3
      break
    case !!tx.update_tx:
      state = 2
      break
    case !!tx.dispatch_tx:
      state = 1
      break
    default:
      state = 0
  }
  return state
}

async function fetchGraphQL(
  operationsDoc: any,
  operationName: string,
  variables: any
) {
  const result = await fetch(nomadAPI, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'x-hasura-admin-secret': process.env.VUE_APP_HASURA_ADMIN_SECRET,
    },
    body: JSON.stringify({
      query: operationsDoc,
      variables: variables,
      operationName: operationName,
    }),
  })

  return await result.json()
}

export async function getTx(messageHashOrDispatch: string): Promise<IndexerTx> {
  const table = `bridge_events${suffix}`
  const query = `
  query MyQuery {
    ${table}(where: {_or: [{dispatch_tx: {_eq: "${messageHashOrDispatch}"}}, {message_hash: {_eq: "${messageHashOrDispatch}"}}]}) {
        ${bridgeColumns}
      }
    }
  `
  const { errors, data } = await fetchGraphQL(query, 'MyQuery', {})

  if (errors) {
    throw new Error(errors[0])
  }

  console.log(data)
  return data[table][0]
}

export async function getTxHistory(
  address: string,
  pageNum: number,
  size: number
): Promise<IndexerTx[]> {
  console.log(address, pageNum, size)
  const offset = (pageNum - 1) * size
  const table = `bridge_events${suffix}`
  const query = `
    query MyQuery {
      ${table}(where: {message__action__to: {_eq: "${address.toLowerCase()}"}}, limit: ${size}, offset: ${offset}, order_by: {dispatched_at: desc}) {
        ${bridgeColumns}
      }
    }
  `
  const { errors, data } = await fetchGraphQL(query, 'MyQuery', {})

  if (errors) {
    throw new Error(errors[0])
  }

  console.log(data)
  return data[table]
}

export async function getTxHistoryCount(address: string) {
  const table = `bridge_events${suffix}_aggregate`
  const query = `
    query MyQuery {
      ${table}(where: {message__action__to: {_eq: "${address.toLowerCase()}"}}) {
        aggregate {
          count(distinct: true)
        }
      }
    }
  `
  const { errors, data } = await fetchGraphQL(query, 'MyQuery', {})

  if (errors) {
    throw new Error(errors[0])
  }

  const count = data[table].aggregate.count
  console.log('total transactions:', count)
  return count
}

export async function getUserNfts(
  address: string,
  pageNum = 1,
  size = 10
): Promise<ProcessFailure[]> {
  console.log(address, pageNum, size)
  const offset = (pageNum - 1) * size
  const table = `${prefix}process_failure_view`
  const query = `
    query MyQuery {
      ${table}(limit: ${size}, offset: ${offset}, where: {recipient: {_eq: "${address.toLowerCase()}"}}, order_by: {timestamp: desc}) {
        id_param
        asset
        recipient
        amount
      }
    }
  `
  const { errors, data } = await fetchGraphQL(query, 'MyQuery', {})

  if (errors) {
    throw new Error(errors[0])
  }

  return data[table]
}

export async function getNftByTxHash(
  processTx: string
): Promise<ProcessFailure> {
  const table = `${prefix}process_failure_view`
  const query = `
    query MyQuery {
      ${table}(limit: 1, where: {transaction_hash: {_eq: "${processTx}"}}) {
        id_param
        asset
        recipient
        amount
      }
    }
  `
  const { errors, data } = await fetchGraphQL(query, 'MyQuery', {})

  if (errors) {
    throw new Error(errors[0])
  }

  return data[table][0]
}

export async function bridgeMessageFromMessageHash(
  context: BridgeContext,
  messageHash: string
): Promise<BridgeMessage> {
  const ms = await getTx(messageHash)
  if (!ms) throw new Error('Unable to fetch message details')
  const dispatch = {
    args: {
      messageHash: ms.message_hash,
      leafIndex: BigNumber.from(ms.leaf_index),
      destinationAndNonce: BigNumber.from(ms.destination_and_nonce),
      committedRoot: ms.committed_root,
      message: ms.message,
    },
    transactionHash: ms.dispatch_tx,
  }
  const tokenId = {
    domain: ms.message__token__domain,
    id: ms.message__token__id,
  }
  return new nomadSDK.BridgeMessage(context, dispatch, tokenId, true)
}

export async function getNftRecoveries(id: BigNumber): Promise<Recovery[]> {
  const table = `${prefix}recovery_view`
  const query = `
    query MyQuery {
      ${table}(where: {id_param: {_eq: "${id.toString()}"}}, order_by: {timestamp: desc}) {
        id
        timestamp
        transaction_hash
        contract_id
        id_param
        asset
        amount
        recipient
      }
    }
  `
  const { errors, data } = await fetchGraphQL(query, 'MyQuery', {})

  if (errors) {
    throw new Error(errors[0])
  }

  return data[table]
}
