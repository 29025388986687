import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35921b3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-col mx-auto w-max mt-10" }
const _hoisted_2 = { class: "text-2xl text-left mb-4 ml-4" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-row"
}
const _hoisted_4 = { class: "step-img mb-0 mr-6" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "flex flex-col justify-between w-[225px] mt-2" }
const _hoisted_7 = { class: "text-lg mb-1.5" }
const _hoisted_8 = { class: "w-full flex my-6" }
const _hoisted_9 = {
  key: 1,
  class: "flex flex-row"
}
const _hoisted_10 = { class: "step-img mb-0 mr-6" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "flex flex-col justify-between w-[225px] mt-2" }
const _hoisted_13 = { class: "text-lg mb-1.5" }
const _hoisted_14 = { class: "w-full flex my-6" }
const _hoisted_15 = {
  key: 2,
  class: "flex flex-row"
}
const _hoisted_16 = { class: "step-img mb-0 mr-6" }
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "flex flex-col justify-between w-[225px] mt-2" }
const _hoisted_19 = { class: "text-lg mb-1.5" }
const _hoisted_20 = { class: "w-full flex my-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_connect_wallet = _resolveComponent("connect-wallet")!
  const _component_nomad_button = _resolveComponent("nomad-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.truncateAddr(_ctx.address)), 1),
    (!_ctx.address)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("img", {
              src: _ctx.noAddress.img
            }, null, 8, _hoisted_5)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.noAddress.title), 1),
              _createElementVNode("div", null, _toDisplayString(_ctx.noAddress.description), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_connect_wallet, {
                class: "next",
                text: _ctx.noAddress.button,
                primary: ""
              }, null, 8, ["text"])
            ])
          ])
        ]))
      : (_ctx.allowed)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("img", {
                src: _ctx.allowedText.img
              }, null, 8, _hoisted_11)
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.allowedText.title), 1),
                _createElementVNode("div", null, _toDisplayString(_ctx.allowedText.description), 1)
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_nomad_button, {
                  class: "next",
                  primary: "",
                  onClick: _ctx.toBridge
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.allowedText.button), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("img", {
                src: _ctx.notAllowedText.img
              }, null, 8, _hoisted_17)
            ]),
            _createElementVNode("div", _hoisted_18, [
              _createElementVNode("div", null, [
                _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.notAllowedText.title), 1),
                _createElementVNode("div", null, _toDisplayString(_ctx.notAllowedText.description), 1)
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createVNode(_component_nomad_button, {
                  class: "next",
                  primary: "",
                  onClick: _ctx.toCoinlistVerify
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.notAllowedText.button), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ])
            ])
          ]))
  ]))
}