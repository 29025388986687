import { getNetworksConfig, getNetworksArr } from './utils'
import { tokensList } from './tokens'

const {
  VUE_APP_NOMAD_ENVIRONMENT,
  VUE_APP_PROOFS_S3,
  VUE_APP_NOMAD_API,
  VUE_APP_TERMS_AGREEMENTS,
} = process.env

const environment = VUE_APP_NOMAD_ENVIRONMENT

export const isProduction = environment === 'production'
export const tokens = tokensList
export const tokensArr = Object.values(tokens)
export const networks = getNetworksConfig()
export const networksArr = getNetworksArr(networks)
export const proofsS3 = VUE_APP_PROOFS_S3
export const nomadAPI = VUE_APP_NOMAD_API
export const termsAPI = VUE_APP_TERMS_AGREEMENTS
export const BUFFER_CONFIRMATION_TIME_IN_MINUTES = isProduction ? 25 : 5
export const PROCESS_TIME_IN_MINUTES = isProduction ? 10 : 2
export const AFFECTED_NETWORK = isProduction ? 'ethereum' : 'goerli'
export const COINLIST_VERIFY_LINK =
  'https://verifyplus.coinlist.co/nomad-recovery/'
