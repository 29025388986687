
import { defineComponent, computed } from 'vue'
import * as Sentry from '@sentry/browser'
import { useStore } from '@/store'
import { RouterView } from 'vue-router'
import { getNetworkByDomainID } from '@/utils'
import analytics from '@/services/analytics'

import Nav from '@/components/Layout/Nav.vue'
import Footer from '@/components/Layout/Footer.vue'
import CardAlert from '@/components/CardAlert.vue'
import TermsModal from '@/views/TermsModal.vue'
import BridgeModal from '@/views/Bridge/Main.vue'
import NetworkModal from '@/components/NetworkModal.vue'
// import NetworkAlert from '@/components/NetworkAlert.vue'

export default defineComponent({
  components: {
    RouterView,
    Nav,
    Footer,
    CardAlert,
    TermsModal,
    BridgeModal,
    NetworkModal,
    // NetworkAlert,
  },

  data: () => ({
    failedHomes: new Set(),
  }),

  setup: () => {
    const store = useStore()

    return {
      originNetwork: computed(() => store.state.userInput.originNetwork),
      destinationNetwork: computed(
        () => store.state.userInput.destinationNetwork
      ),
      address: computed(() => store.state.wallet.address),
      store,
    }
  },

  async mounted() {
    if (this.address) {
      Sentry.setUser({ id: this.address })
      this.store.dispatch('checkAllowed')
    }

    // set failedHomes
    this.store.dispatch('checkFailedHomes')
    this.checkNetworks()
    setInterval(() => {
      this.checkNetworks()
    }, 30000)
  },

  methods: {
    getNetworkByDomainID,
    checkNetworks() {
      console.log('checking home state...')
      this.failedHomes = this.store.getters.blacklist()
      if (this.failedHomes.size === 0) {
        console.log('all networks active')
      } else {
        console.log('networks down: ', this.failedHomes)
      }
    },
  },

  watch: {
    address(newAddr) {
      if (newAddr) {
        Sentry.setUser({ id: newAddr })
      }
    },
    $route(to) {
      // track page changes
      analytics.page(to.name)
    },
  },
})
