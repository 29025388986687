
import { defineComponent } from 'vue'
import { NTime, NDivider } from 'naive-ui'
import CopyHash from '@/components/CopyHash.vue'
import { toDecimals } from '@/utils'
import { AFFECTED_NETWORK, networks } from '@/config'

export default defineComponent({
  components: { CopyHash, NTime, NDivider },
  props: {
    token: Object,
    recovered: Object,
    history: Array,
  },
  methods: {
    toDecimals,
    formatTime(timestamp: string) {
      return new Date(Number.parseInt(timestamp) * 1000)
    },
    viewInExplorer(txHash: string) {
      window.open(`${networks[AFFECTED_NETWORK].blockExplorer}tx/${txHash}`)
    },
  },
})
