import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "flex justify-between flex-wrap max-w-screen-xl mx-auto p-6 pb-18" }
const _hoisted_2 = { class: "flex flex-col justify-between h-[105px] pb-5 md:pb-0" }
const _hoisted_3 = { class: "flex items-center opacity-70 mb-2" }
const _hoisted_4 = ["href"]
const _hoisted_5 = { class: "flex justify-between w-full sm:w-1/3" }
const _hoisted_6 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_n_text = _resolveComponent("n-text")!

  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.socials, (s) => {
          return (_openBlock(), _createElementBlock("a", {
            key: s.href,
            href: s.href,
            target: "_blank",
            class: "mr-6"
          }, [
            _createVNode(_component_n_icon, {
              size: "22",
              color: "rgba(255, 255, 255imti/footer-links, 0.6)"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(s.icon)))
              ]),
              _: 2
            }, 1024)
          ], 8, _hoisted_4))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.products, (p) => {
          return (_openBlock(), _createElementBlock("li", {
            key: p.href,
            class: "pb-2 last:pb-0 opacity-60"
          }, [
            _createElementVNode("a", {
              href: p.href,
              class: "hover:underline",
              target: "_blank"
            }, _toDisplayString(p.label), 9, _hoisted_6)
          ]))
        }), 128))
      ]),
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.legals, (l) => {
          return (_openBlock(), _createElementBlock("li", {
            key: l.href,
            class: "pb-2 last:pb-0 opacity-60"
          }, [
            _createVNode(_component_router_link, {
              to: l.href,
              class: "hover:underline",
              target: "_blank"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(l.label), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
        }), 128)),
        _createElementVNode("li", null, [
          _createVNode(_component_n_text, { class: "opacity-40 text-xs" }, {
            default: _withCtx(() => [
              _createTextVNode(" © " + _toDisplayString(_ctx.currentYear) + " Nomad ", 1)
            ]),
            _: 1
          })
        ])
      ])
    ])
  ]))
}