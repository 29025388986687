import { BigNumber } from '@ethersproject/bignumber'
import { BridgeContext } from '@nomad-xyz/sdk-bridge'
import { NetworkName, TokenMetadata } from '@/config/types'
import { TokenBalance } from '@/store/modules/sdk'

export async function getNativeBalance(
  context: BridgeContext,
  network: NetworkName,
  address: string
): Promise<BigNumber | undefined> {
  const provider = context.getProvider(network)
  return await provider?.getBalance(address)
}

export async function getERC20Balance(
  context: BridgeContext,
  network: NetworkName,
  token: TokenMetadata,
  account: string
): Promise<TokenBalance | undefined> {
  const tokenContract = await context.resolveRepresentation(
    network,
    token.tokenIdentifier
  )
  if (!tokenContract) return
  const balance = await tokenContract.balanceOf(account)
  if (!balance) return
  return {
    token: token.key,
    balance,
  }
}
