
import { defineComponent } from 'vue'
import { NCollapseTransition, NIcon } from 'naive-ui'
import { CloseOutline, AlertCircleOutline } from '@vicons/ionicons5'
export default defineComponent({
  emits: ['close'],
  props: {
    title: String,
    show: {
      type: Boolean,
      default: true,
    },
    closeable: {
      type: Boolean,
      default: false,
    },
    warning: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    NCollapseTransition,
    CloseOutline,
    AlertCircleOutline,
    NIcon,
  },
})
