
import { defineComponent, ref } from 'vue'
import { ChevronBackOutline, ChevronForwardOutline } from '@vicons/ionicons5'
import { NIcon } from 'naive-ui'

import { useStore } from '@/store'

type ComponentData = {
  data: Array<unknown>
  pollActiveTxs: number | null
}

export default defineComponent({
  emit: ['fetch'],
  props: {
    pageCount: {
      type: Number,
      required: true,
    },
    size: {
      type: Number,
      required: true,
    },
    pollInterval: Number,
  },

  components: {
    ChevronBackOutline,
    ChevronForwardOutline,
    NIcon,
  },

  data() {
    return {
      data: [],
      pollActiveTxs: null,
    } as ComponentData
  },

  setup: () => {
    const store = useStore()

    return {
      store,
      page: ref(1),
    }
  },

  mounted() {
    this.fetch()
    if (this.pollInterval) {
      this.pollActiveTxs = window.setInterval(this.fetch, this.pollInterval)
    }
  },

  unmounted() {
    this.clearPollActiveTxs()
  },

  methods: {
    async changePage(page: number) {
      if (page <= 0) return
      if (this.pageCount && page > this.pageCount) return
      this.page = page
      this.fetch()
    },

    fetch() {
      this.$emit('fetch', this.page)
    },

    clearPollActiveTxs() {
      if (this.pollActiveTxs) {
        window.clearInterval(this.pollActiveTxs)
        this.pollActiveTxs = null
      }
    },
  },
})
