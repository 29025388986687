
import { computed, defineComponent } from 'vue'
import { NCheckbox } from 'naive-ui'
import { useStore } from '@/store'
import { AFFECTED_NETWORK, networks } from '@/config'
import { countDecimals, getDisplayName, truncateAddr } from '@/utils'
import NomadButton from '@/components/Button.vue'
import NftPreview from '@/components/NFTPreview.vue'

export default defineComponent({
  components: { NomadButton, NftPreview, NCheckbox },
  setup: () => {
    const store = useStore()

    return {
      userInput: computed(() => store.state.userInput),
      userAddr: computed(() => store.state.wallet.address),
      token: computed(() => store.state.userInput.token),
      allowed: computed(() => store.state.wallet.allowed),
      store,
    }
  },
  data: () => ({
    title: 'Take a moment to review this transaction',
    checkbox1: false,
    checkbox2: false,
    truncateAddr,
  }),
  methods: { getDisplayName },
  computed: {
    valid() {
      const { originNetwork, destinationNetwork, token, sendAmount } =
        this.userInput
      if (this.affected && !this.checkbox1) return false
      if (!this.checkbox2) return false
      if (
        !this.allowed ||
        !this.userAddr ||
        !originNetwork ||
        !destinationNetwork ||
        destinationNetwork !== token.nativeNetwork ||
        !token.symbol ||
        !token.decimals ||
        !sendAmount ||
        sendAmount <= 0
      )
        return false
      const numDecimals = countDecimals(sendAmount)
      if (numDecimals > 6) return false
      return true
    },
    affected() {
      if (!this.token) return false
      return this.token.affected && this.token.tokenIdentifier.domain === AFFECTED_NETWORK && this.userInput.destinationNetwork === AFFECTED_NETWORK
    },
    nativeToken() {
      const { destinationNetwork } = this.userInput
      if (!destinationNetwork) return ''
      return networks[destinationNetwork].nativeSymbol
    }
  },
})
