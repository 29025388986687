
import { defineComponent, shallowRef } from 'vue'
import { NText, NIcon } from 'naive-ui'
import { LogoTwitter, LogoMedium, LogoDiscord } from '@vicons/ionicons5'

export default defineComponent({
  components: {
    NText,
    NIcon,
  },
  // NOTE: if we use the links + labels elsewhere,
  // consider pulling it out to a separate file
  data: () => ({
    currentYear: new Date().getFullYear(),
    socials: [
      {
        icon: shallowRef(LogoTwitter),
        href: 'https://www.twitter.com/nomadxyz_',
        alt: 'twitter',
      },
      {
        icon: shallowRef(LogoDiscord),
        href: 'https://discord.gg/RurtmJApqm',
        alt: 'discord',
      },
      {
        icon: shallowRef(LogoMedium),
        href: 'https://blog.nomad.xyz/',
        alt: 'medium',
      },
    ],
    products: [
      {
        href: 'https://docs.nomad.xyz',
        label: 'Docs',
      },
      {
        href: 'https://github.com/nomad-xyz/nomad-monorepo',
        label: 'GitHub',
      },
      {
        href: 'https://forum.nomad.xyz',
        label: 'Forum',
      },
      {
        href: 'https://discord.gg/pqd4p9Mnre',
        label: 'Support',
      },
    ],
    legals: [
      {
        href: '/terms',
        label: 'Terms & Conditions',
      },
      {
        href: '/privacy-policy',
        label: 'Privacy Policy',
      },
    ],
  }),
})
