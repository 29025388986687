import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "bg-[#2f2f2f] rounded-xl" }
const _hoisted_2 = { class: "p-4 flex justify-between" }
const _hoisted_3 = { class: "flex flex-col" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = {
  key: 0,
  class: "opacity-80"
}
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  key: 1,
  class: "opacity-80"
}
const _hoisted_12 = {
  key: 2,
  class: "text-semibold text-lg"
}
const _hoisted_13 = { key: 1 }
const _hoisted_14 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkmark_circle = _resolveComponent("checkmark-circle")!
  const _component_alert_circle = _resolveComponent("alert-circle")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_connect_wallet = _resolveComponent("connect-wallet")!
  const _component_n_spin = _resolveComponent("n-spin")!
  const _component_nomad_button = _resolveComponent("nomad-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_n_icon, {
            size: "18",
            class: "mr-1"
          }, {
            default: _withCtx(() => [
              (_ctx.status === _ctx.TxStatus.PROCESSED)
                ? (_openBlock(), _createBlock(_component_checkmark_circle, {
                    key: 0,
                    filled: "",
                    color: "#2AA665"
                  }))
                : (_openBlock(), _createBlock(_component_alert_circle, {
                    key: 1,
                    filled: "",
                    color: "#5C85CA"
                  }))
            ]),
            _: 1
          }),
          (_ctx.status === _ctx.TxStatus.PROCESSED)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, "Complete"))
            : (_ctx.manualProcess && _ctx.readyToProcess)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, "Ready"))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, "In Progress"))
        ]),
        (_ctx.status === _ctx.TxStatus.PROCESSED)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              (_ctx.affected)
                ? (_openBlock(), _createElementBlock("div", _hoisted_9, "NFT Received"))
                : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.token.symbol) + " Received", 1))
            ]))
          : (_ctx.manualProcess && _ctx.readyToProcess)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, " Complete Transfer "))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.eta), 1))
      ]),
      (_ctx.manualProcess && _ctx.readyToProcess && !_ctx.connected)
        ? (_openBlock(), _createBlock(_component_connect_wallet, {
            key: 0,
            text: "Connect Wallet",
            primary: ""
          }))
        : (_ctx.manualProcess && _ctx.status < _ctx.TxStatus.PROCESSED)
          ? (_openBlock(), _createBlock(_component_nomad_button, {
              key: 1,
              primary: "",
              disabled: !_ctx.readyToProcess || _ctx.processing,
              onClick: _ctx.processMessage
            }, {
              default: _withCtx(() => [
                (_ctx.processing)
                  ? (_openBlock(), _createBlock(_component_n_spin, {
                      key: 0,
                      size: "tiny",
                      stroke: "#000",
                      class: "w-14"
                    }))
                  : (_ctx.affected)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, "Mint NFT"))
                    : (_openBlock(), _createElementBlock("div", _hoisted_14, "Receive " + _toDisplayString(_ctx.token.symbol), 1))
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]))
          : (_ctx.affected)
            ? (_openBlock(), _createBlock(_component_nomad_button, {
                key: 2,
                primary: "",
                disabled: !_ctx.processTx,
                onClick: _ctx.viewNft
              }, {
                default: _withCtx(() => [
                  _createTextVNode("View NFT")
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]))
            : (_openBlock(), _createBlock(_component_nomad_button, {
                key: 3,
                primary: "",
                disabled: !_ctx.processTx,
                onClick: _ctx.viewReceiveTransaction
              }, {
                default: _withCtx(() => [
                  _createTextVNode("View Transaction")
                ]),
                _: 1
              }, 8, ["disabled", "onClick"]))
    ])
  ]))
}