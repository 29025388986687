import { TokenIdentifierMap, TokenMetadataMap } from '../types'

import USDCIcon from '@/assets/token-logos/USDC.svg'
import sWETHIcon from '@/assets/token-logos/sWETH.png'
import gWETHIcon from '@/assets/token-logos/gWETH.png'
import TESTIcon from '@/assets/token-logos/TEST.svg'
import sWETHThumbnail from '@/assets/nft-thumbnails/img-1.png'
import gWETHThumbnail from '@/assets/nft-thumbnails/img-2.png'
import TESTThumbnail from '@/assets/nft-thumbnails/img-3.png'

// IMPORTANT make name same as tokens[token].symbol value
export const tokenIdentifiers: TokenIdentifierMap = {
  sWETH: {
    domain: 'sepolia',
    id: '0x9af0c1fa99a11d2b8a7642b4abcaae2f632a40be',
  },
  gWETH: {
    domain: 'goerli',
    id: '0x0bb7509324ce409f7bbc4b701f932eaca9736ab7',
  },
  USDC: {
    domain: 'goerli',
    id: '0x07865c6E87B9F70255377e024ace6630C1Eaa37F',
  },
  TEST: {
    domain: 'goerli',
    id: '0x8a1Cad3703E0beAe0e0237369B4fcD04228d1682',
  },
}

export const developmentTokens: TokenMetadataMap = {
  sWETH: {
    key: 'sWETH',
    nativeNetwork: 'sepolia',
    symbol: 'sWETH',
    name: 'Sepolia WETH',
    icon: sWETHIcon,
    decimals: 18,
    coinGeckoId: 'weth',
    tokenIdentifier: tokenIdentifiers.sWETH,
    affected: true,
    nftThumbnail: sWETHThumbnail,
  },
  gWETH: {
    key: 'gWETH',
    nativeNetwork: 'goerli',
    symbol: 'gWETH',
    name: 'Goerli WETH',
    icon: gWETHIcon,
    decimals: 18,
    coinGeckoId: 'weth',
    tokenIdentifier: tokenIdentifiers.gWETH,
    affected: true,
    nftThumbnail: gWETHThumbnail,
  },
  USDC: {
    key: 'USDC',
    nativeNetwork: 'goerli',
    symbol: 'USDC',
    name: 'USDC',
    icon: USDCIcon,
    decimals: 6,
    coinGeckoId: 'usd-coin',
    tokenIdentifier: tokenIdentifiers.USDC,
  },
  TEST: {
    key: 'TEST',
    nativeNetwork: 'goerli',
    symbol: 'TEST',
    name: 'Goerli TEST',
    icon: TESTIcon,
    decimals: 18,
    coinGeckoId: 'dai',
    tokenIdentifier: tokenIdentifiers.TEST,
    affected: true,
    nftThumbnail: TESTThumbnail,
  },
}
