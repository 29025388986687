import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex flex-row justify-between items-center mt-20 mb-6" }
const _hoisted_3 = { class: "text-lg font-semibold" }
const _hoisted_4 = { class: "flex flex-col items-end" }
const _hoisted_5 = { class: "opacity-70" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "flex flex-col items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_copy_hash = _resolveComponent("copy-hash")!
  const _component_n_time = _resolveComponent("n-time")!
  const _component_n_divider = _resolveComponent("n-divider")!

  return (_ctx.history.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.history.length) + " transaction" + _toDisplayString(_ctx.history.length > 1 ? 's' : ''), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", null, _toDisplayString(_ctx.toDecimals(_ctx.recovered, _ctx.token.decimals, 6)), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.token.symbol), 1)
          ])
        ]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.history, (recovery) => {
          return (_openBlock(), _createElementBlock("div", {
            key: recovery.id
          }, [
            _createElementVNode("div", {
              class: "flex flex-row justify-between cursor-pointer p-4 rounded-md hover:bg-white hover:bg-opacity-10",
              onClick: ($event: any) => (_ctx.viewInExplorer(recovery.transaction_hash))
            }, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_copy_hash, {
                  address: recovery.transaction_hash
                }, null, 8, ["address"]),
                _createVNode(_component_n_time, {
                  time: _ctx.formatTime(recovery.timestamp)
                }, null, 8, ["time"])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", null, _toDisplayString(_ctx.toDecimals(recovery.amount, _ctx.token.decimals, 6)), 1),
                _createElementVNode("div", null, _toDisplayString(_ctx.token.symbol), 1)
              ])
            ], 8, _hoisted_6),
            _createVNode(_component_n_divider, { class: "n-div-margin" })
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}