
import { computed, defineComponent } from 'vue'

const buttonProps = {
  onClick: Function,
  primary: Boolean,
  secondary: Boolean,
  tertiary: Boolean,
  disabled: Boolean,
  responsive: Boolean,
}

const cssClasses = {
  base: `
    flex
    items-center
    rounded-lg
    tracking-wide
  `,
  default: `
    text-sm px-4 py-2
  `,
  primary: `
    bg-white text-black
  `,
  secondary: `
    bg-[#272829] text-white
  `,
  responsive: `
    px-3 py-1 text-xs md:px-4 md:py-2 md:text-sm
  `,
  disabled: `bg-opacity-70 cursor-not-allowed`,
}

export default defineComponent({
  name: 'nomad-button',
  props: buttonProps,
  setup(props) {
    return {
      styles: computed(() => {
        let styles = cssClasses.base
        if (props.responsive) {
          styles += cssClasses.responsive
        } else {
          styles += cssClasses.default
        }

        if (props.primary) {
          styles += cssClasses.primary
        } else if (props.secondary) {
          styles += cssClasses.secondary
        }

        if (props.disabled) {
          styles += cssClasses.disabled
        }

        return styles
      }),
    }
  },
})
