
import { BigNumber } from '@ethersproject/bignumber'
import { defineComponent } from 'vue'
import { getTokenByTokenID, toDecimals } from '@/utils'
import { TokenMetadata } from '@/config/types'
import { AFFECTED_NETWORK, isProduction } from '@/config'

export default defineComponent({
  props: {
    size: {
      type: Number,
      required: false,
      default: 300,
    },
    asset: {
      type: String || Number,
      required: false,
    },
    amount: {
      type: String || Number,
      required: false,
    },
    affected: {
      type: Boolean,
      default: true,
    },
    hover: {
      type: Boolean,
      required: false,
    },
    pending: {
      type: Boolean,
      required: false,
    },
    indicator: {
      type: Boolean,
      default: false,
    },
    fade: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    transition: '',
    emDash: '—',
    isProduction,
  }),
  mounted() {
    this.transition = 'fade'
  },
  methods: {
    randomIntFromInterval() {
      const min = 1
      const max = 7
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
  },
  computed: {
    token(): TokenMetadata | undefined {
      if (!this.asset) return
      const tokenId = {
        domain: AFFECTED_NETWORK,
        id: this.asset,
      }
      const token = getTokenByTokenID(tokenId)
      if (!token) return
      return token
    },
    assetAmount() {
      if (!this.asset || !this.amount) return ''
      if (typeof this.amount === 'number') return `${this.amount}`
      const tokenId = {
        domain: AFFECTED_NETWORK,
        id: this.asset,
      }
      const token = getTokenByTokenID(tokenId)
      if (!token) return ''
      return toDecimals(BigNumber.from(this.amount), token.decimals, 6)
    },
  },
})
