
import { defineComponent, computed } from 'vue'
import { NInput, useNotification } from 'naive-ui'
import analytics from '@/services/analytics'
import { useStore } from '@/store'
import { COINLIST_VERIFY_LINK } from '@/config'
import NomadButton from '@/components/Button.vue'
import ConnectWallet from '@/components/ConnectWallet.vue'

export default defineComponent({
  components: {
    NInput,
    NomadButton,
    ConnectWallet,
  },
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    transaction: '',
  }),
  setup: () => {
    const store = useStore()
    const notification = useNotification()
    return {
      walletConnected: computed(() => store.state.wallet.connected),
      walletAddr: computed(() => store.state.wallet.address),
      allowed: computed(() => store.state.wallet.allowed),
      notification,
      store,
    }
  },
  methods: {
    toHome() {
      this.$emit('hide')
      this.$router.push('/')
    },
    toTx() {
      if (
        this.transaction.length === 66 &&
        this.transaction.slice(0, 2) === '0x'
      ) {
        this.$router.push(`/tx/${this.transaction}`)
      } else {
        this.notification.warning({
          title: 'Invalid Input',
          duration: 3000,
        })
      }
    },
    async toBridge() {
      if (this.walletConnected) {
        this.$router.push('/bridge')
      } else {
        this.notification.info({
          title: 'Connect Wallet',
          description: 'You must connect your wallet before bridging',
          duration: 3000,
        })
      }
    },
    async toHistory() {
      this.$emit('hide')
      this.$router.push('/history')
    },
    toFAQ() {
      this.$emit('hide')
      window.open('https://coinlist.co/help/nomad-recovery')
    },
    toCoinList() {
      analytics.track('Opened CoinList Verify Link', {
        walletAddress: this.walletAddr,
      })
      window.open(COINLIST_VERIFY_LINK)
    },
  },
})
