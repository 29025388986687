
import { computed, defineComponent } from 'vue'
import { NTooltip } from 'naive-ui'
import { truncateAddr, copyTextToClipboard } from '@/utils'

export default defineComponent({
  props: {
    address: {
      type: String,
      required: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  components: { NTooltip },
  data() {
    return {
      truncatedAddress: computed(() => truncateAddr(this.address)),
      copyText: 'copy',
    }
  },
  methods: {
    handleCopy() {
      if (!this.address) {
        this.copyText = 'error'
        return
      }
      const success = copyTextToClipboard(this.address)
      if (success) this.copyText = 'copied'
    },
    reset() {
      setTimeout(() => (this.copyText = 'copy'), 500)
    },
  },
})
