// wallet
export const SET_WALLET_CONNECTION = 'wallet/SET_WALLET_CONNECTION'
export const SET_WALLET_ALLOWED = 'wallet/SET_WALLET_ALLOWED'
export const SET_WALLET_NETWORK = 'wallet/SET_WALLET_NETWORK'
export const SET_WALLET_ADDRESS = 'wallet/SET_WALLET_ADDRESS'
export const SHOW_SWITCH_PROMPT = 'wallet/SHOW_SWITCH_PROMPT'

// userInput
export const SET_INPUT_STEP = 'userInput/SET_INPUT_STEP'
export const SET_ORIGIN_NETWORK = 'userInput/SET_ORIGIN_NETWORK'
export const SET_TOKEN = 'wallet/SET_WALLET_TOKEN'
export const SET_SEND_AMOUNT = 'userInput/SET_SEND_AMOUNT'
export const SET_DESTINATION_NETWORK = 'userInput/SET_DESTINATION_NETWORK'
export const SET_GAS_EST = 'userInput/SET_GAS_EST'

// sdk
export const SET_BALANCE = 'sdk/SET_BALANCE'
export const SET_ERC20_BALANCE = 'sdk/SET_ERC20_BALANCE'
export const SET_ERC20_BALANCES = 'sdk/SET_ERC20_BALANCES'
export const SET_SENDING = 'sdk/SET_SENDING'
export const SET_PROCESSING = 'sdk/SET_PROCESSING'
export const SET_BLACKLIST = 'sdk/SET_BLACKLIST'
