
import { defineComponent, computed, h } from 'vue'
import { useNotification } from 'naive-ui'
import { utils } from 'ethers'
import { useStore } from '@/store'
import { AFFECTED_NETWORK } from '@/config'
import NftPreview from '@/components/NFTPreview.vue'
import NotificationError from '@/components/NotificationError.vue'

export default defineComponent({
  components: { NftPreview },
  setup: () => {
    const store = useStore()
    const notification = useNotification()

    return {
      token: computed(() => store.state.userInput.token),
      destNet: computed(() => store.state.userInput.destinationNetwork),
      amountBN: computed(() => {
        const { sendAmount, token } = store.state.userInput
        const parsedAmt = utils.parseUnits(`${sendAmount}`, token.decimals)
        if (parsedAmt.isZero()) return
        return parsedAmt.toString()
      }),
      allowed: computed(() => store.state.wallet.allowed),
      notification,
      store,
    }
  },
  mounted() {
    this.send()
  },
  methods: {
    async send() {
      await this.store.dispatch('checkAllowed')
      if (!this.allowed) {
        this.notification.error({
          title: 'Bridging not available',
          description: 'Please check your status with CoinList Verify+',
        })
        throw new Error(`Bridge send failed, user not allowed`)
      }

      let transferMessage
      try {
        transferMessage = await this.store.dispatch('send')
      } catch (e: any) {
        const m = e.message.toLowerCase()
        if (m.includes('user denied') || m.includes('user rejected')) return
        this.notification.error({
          title: 'Error completing transaction',
          content: () =>
            h(NotificationError, {
              text: 'Please contact support in our Discord server',
              error: e as Error,
            }),
        })
        this.$emit('back')
      }
      const { messageHash } = transferMessage.dispatch.args
      setTimeout(() => {
        this.$router.push(`/tx/${messageHash}`)
      }, 3000)
    },
  },
  computed: {
    affected() {
      if (!this.token) return false
      return this.token.affected && this.token.tokenIdentifier.domain === AFFECTED_NETWORK && this.destNet === AFFECTED_NETWORK
    },
  }
})
