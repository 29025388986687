import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center justify-between mb-8" }
const _hoisted_2 = { class: "text-xl" }
const _hoisted_3 = { class: "flex flex-wrap justify-center gap-y-4 min-h-[400px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_connect_wallet = _resolveComponent("connect-wallet")!
  const _component_nft_preview = _resolveComponent("nft-preview")!
  const _component_paginate = _resolveComponent("paginate")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.nfts.length) + " NFTs", 1)
      ]),
      _createVNode(_component_connect_wallet, {
        text: "Get another NFT",
        primary: "",
        onConnected: _ctx.toBridge
      }, null, 8, ["onConnected"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nfts, (nft, i) => {
        return (_openBlock(), _createBlock(_component_nft_preview, {
          key: i,
          size: 180,
          asset: nft.asset,
          amount: nft.amount,
          hover: "",
          class: "m-[10px]",
          onClick: ($event: any) => (_ctx.handleRecover(nft.id_param))
        }, null, 8, ["asset", "amount", "onClick"]))
      }), 128))
    ]),
    _createVNode(_component_paginate, {
      size: _ctx.size,
      pollInterval: 60000,
      pageCount: _ctx.pageCount,
      onFetch: _ctx.getHistory,
      class: "mt-6"
    }, null, 8, ["size", "pageCount", "onFetch"])
  ], 64))
}