
import { defineComponent, computed } from 'vue'
import { NIcon, NSpin } from 'naive-ui'
import { CheckmarkCircle, AlertCircle } from '@vicons/ionicons5'
import { useStore } from '@/store'
import { TxStatus, getNftByTxHash } from '@/utils/nomadAPI'
import { isProduction, networks } from '@/config'
import NomadButton from '@/components/Button.vue'
import ConnectWallet from '@/components/ConnectWallet.vue'

export default defineComponent({
  emits: ['process', 'ready'],
  props: {
    token: {
      type: Object,
      required: true,
    },
    affected: {
      type: Boolean,
      required: true,
    },
    status: {
      type: Number,
      required: true,
    },
    confirmAt: {
      type: Number,
      required: true,
    },
    destNet: {
      type: String,
      required: true,
    },
    processTx: {
      type: String,
      required: false,
    },
  },
  components: {
    NIcon,
    CheckmarkCircle,
    AlertCircle,
    NomadButton,
    NSpin,
    ConnectWallet,
  },
  setup: () => {
    const store = useStore()
    const nomadSDK = store.getters.bridgeContext()

    return {
      connected: computed(() => store.state.wallet.connected),
      processing: computed(() => store.state.sdk.processing),
      origin: computed(() => store.state.userInput.originNetwork),
      config: nomadSDK.conf,
      store,
    }
  },
  data: () => ({
    now: Date.now(), // current timestamp in seconds
    TxStatus,
  }),
  async mounted() {
    this.setNow()
    setInterval(() => {
      this.setNow()
    }, 10000)
  },

  methods: {
    processMessage() {
      this.$emit('process')
    },
    setNow() {
      const seconds = Date.now() / 1000
      this.now = Math.floor(seconds)
    },
    viewReceiveTransaction() {
      window.open(this.receiveLink)
    },
    async viewNft() {
      if (!this.processTx) return
      // note: will not work with mock accountant in development, push to random nft
      if (!isProduction) {
        this.$router.push('/recover/1')
        return
      }
      const nft = await getNftByTxHash(this.processTx)
      this.$router.push(`/recover/${nft.id_param.toString()}`)
    },
  },
  computed: {
    readyToProcess(): boolean {
      if (
        !this.manualProcess ||
        !this.confirmAt ||
        this.confirmAt === 0 ||
        !this.destNet ||
        this.status === TxStatus.PROCESSED
      )
        return false
      const { optimisticSeconds } = networks[this.destNet]
      if (!optimisticSeconds)
        throw new Error(
          'invalid network name or optimistic seconds not defined'
        )
      // confirmAt time in seconds with 2 minute buffer
      const ready = this.now > this.confirmAt
      this.$emit('ready', ready)
      return ready
    },
    manualProcess(): boolean {
      const network = networks[this.destNet]
      if (!network) return false
      return network.manualProcessing
    },
    eta(): string {
      if (!this.config || !this.destNet) return ''
      const { optimisticSeconds } = networks[this.destNet]
      const agentConfig = this.config.agent[this.destNet]
      // 5 minute buffer
      const optimisticMins = optimisticSeconds / 60 + 5
      const updateBuffer = agentConfig.updater.interval
      const relayBuffer = agentConfig.relayer.interval
      const processBuffer = this.manualProcess
        ? 0
        : agentConfig.processor.interval
      if (this.status === TxStatus.DISPATCHED) {
        const minutes =
          updateBuffer + relayBuffer + processBuffer + optimisticMins
        return `~${minutes} minutes`
      } else if (this.status === TxStatus.UPDATED) {
        const minutes = relayBuffer + processBuffer + optimisticMins
        return `~${minutes} minutes`
      } else if (
        this.status === TxStatus.RELAYED &&
        this.now > this.confirmAt
      ) {
        return `~${processBuffer} minutes`
      }
      const minutes = Math.ceil((this.confirmAt - this.now) / 60)
      return `~${minutes} minutes`
    },
    receiveLink(): string {
      if (!this.destNet || !this.processTx) return ''
      const n = networks[this.destNet]
      return `${n.blockExplorer}tx/${this.processTx}`
    },
  },
})
