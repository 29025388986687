
import { defineComponent, computed } from 'vue'
import { useRoute } from 'vue-router'
import { NTooltip } from 'naive-ui'
import { useStore } from '@/store'
import { truncateAddr } from '@/utils'
import NomadButton from '@/components/Button.vue'
import ConnectWallet from '@/components/ConnectWallet.vue'
import MenuItems from './MenuItems.vue'

export default defineComponent({
  components: {
    NTooltip,
    NomadButton,
    MenuItems,
    ConnectWallet,
  },
  setup: () => {
    const store = useStore()
    const route = useRoute()
    return {
      walletConnected: computed(() => store.state.wallet.connected),
      showButton: computed(() =>
        ['Home', 'Bridge', 'Transaction', 'Recover', 'Status'].includes(
          route.name as string
        )
      ),
      store,
    }
  },
  methods: {
    hideMenu() {
      ;(this.$refs.menu as any).setShow(false)
    },
  },
  computed: {
    truncatedAddress(): string {
      const { address, connected } = this.store.state.wallet
      return connected ? truncateAddr(address) : ''
    },
  },
})
