
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'

import Guide from './Guide.vue'
import NftList from './NFTList.vue'
import { getUserNfts, ProcessFailure } from '@/utils/nomadAPI'

export default defineComponent({
  components: {
    Guide,
    NftList,
  },
  setup: () => {
    const store = useStore()

    return {
      userAddress: computed(() => store.state.wallet.address),
      allowed: computed(() => store.state.wallet.allowed),
    }
  },
  data: () => ({
    nfts: undefined as ProcessFailure[] | undefined,
  }),
  async mounted() {
    this.nfts = await getUserNfts(this.userAddress)
  },
})
