
import { defineComponent, computed } from 'vue'
import { useStore } from '@/store'
import { TokenMetadata } from '@/config/types'
import { AFFECTED_NETWORK } from '@/config'
import { toDecimals, getDisplayName } from '@/utils'
import NomadButton from '@/components/Button.vue'
import Scroll from '@/components/Scroll.vue'

export default defineComponent({
  components: { Scroll, NomadButton },
  setup: () => {
    const store = useStore()

    return {
      origin: computed(() => store.state.userInput.originNetwork),
      destNet: computed(() => store.state.userInput.destinationNetwork),
      selectedToken: computed(() => store.state.userInput.token),
      balances: computed(() => store.state.sdk.balances),
      tokens: store.getters.availableTokens,
      store,
    }
  },
  methods: {
    selectToken(token: TokenMetadata) {
      this.store.dispatch('setToken', token)
      this.store.dispatch('setDestinationNetwork', token.tokenIdentifier.domain)
    },
    back() {
      this.store.dispatch('prevStep')
    },
    getDisplayName,
    toDecimals,
  },
  computed: {
    tokensWithBalance() {
      return this.tokens.filter((t: TokenMetadata) => {
        return !!this.balances[t.key]
      })
    },
    affected() {
      if (!this.selectedToken) return false
      return this.selectedToken.affected && this.selectedToken.tokenIdentifier.domain === AFFECTED_NETWORK && this.destNet === AFFECTED_NETWORK
    },
    valid() {
      return this.selectedToken.symbol
    },
    title() {
      return `Select an asset to bridge back from ${this.getDisplayName(
        this.origin
      )}`
    },
    subtitle() {
      const { symbol } = this.selectedToken
      if (!symbol) return 'Assets will be bridged back to their home chains'
      if (this.affected) {
        return `You will receive an NFT on Ethereum that can be used to access recovered ${symbol}`
      }
      return `This asset is not affected. Once your complete your bridge transfer on Ethereum, you will receive ${symbol} equal to the amount sent.`
    },
  },
})
