
import { shallowRef, defineComponent, computed } from 'vue'
import { utils } from 'ethers'
import { useStore } from '@/store'
import { InputStep } from '@/store/modules/userInput'
import NftPreview from '@/components/NFTPreview.vue'
import Origin from './Origin.vue'
import Asset from './Asset.vue'
import Amount from './Amount.vue'
import Confirm from './Review.vue'
import Sending from './Sending.vue'
import NomadButton from '@/components/Button.vue'
import { NTimeline, NTimelineItem } from 'naive-ui'
import { truncateAddr, getDisplayName } from '@/utils'

type Transition = 'forward' | 'back' | 'none'

export default defineComponent({
  components: {
    NomadButton,
    NftPreview,
    NTimeline,
    NTimelineItem,
    Sending,
    Confirm,
  },
  setup: () => {
    const store = useStore()
    console.log('step', store.state.userInput.step)
    return {
      store,
      sendAmount: computed(() => store.state.userInput.sendAmount),
      selectedToken: computed(() => store.state.userInput.token),
      originNetwork: computed(() => store.state.userInput.originNetwork),
      walletAddress: computed(() => store.state.wallet.address),
      walletConnected: computed(() => store.state.wallet.connected),
      allowed: computed(() => store.state.wallet.allowed),
      currentStep: computed(() => store.state.userInput.step),
      sending: computed(() => store.state.sdk.sending),
    }
  },
  data: () => ({
    InputStep,
    transitionType: 'none' as Transition,
    steps: [
      {
        title: 'Wallet',
      },
      {
        title: 'Origin',
        component: shallowRef(Origin),
      },
      {
        title: 'Asset',
        component: shallowRef(Asset),
      },
      {
        title: 'Amount',
        component: shallowRef(Amount),
      },
      {
        title: 'Review',
        component: shallowRef(Confirm),
      },
      {
        title: 'Sending',
        component: shallowRef(Sending),
      },
    ],
  }),
  mounted() {
    this.store.dispatch('clearInputs')
    if (!this.walletConnected || !this.walletAddress) {
      this.$router.push('/')
    }
    setTimeout(() => {
      if (!this.allowed) {
        this.$router.push('/')
      }
    }, 1000)
  },
  methods: {
    nextStep(step?: number) {
      this.transitionType = 'forward'
      if (step && step > this.currentStep) {
        this.store.dispatch('setStep', step)
      } else {
        this.store.dispatch('nextStep')
      }
    },
    prevStep(step?: number) {
      this.transitionType = 'back'
      if (step && step < this.currentStep) {
        this.store.dispatch('setStep', step)
      } else {
        this.store.dispatch('prevStep')
      }
    },
    exit() {
      if (!this.sending) {
        this.store.dispatch('clearInputs')
        this.$router.back()
      }
    },
    getSubtitle(step: InputStep): string {
      const unspecified = 'Unspecified'
      switch (step) {
        case InputStep.WALLET:
          return this.truncatedAddress || unspecified
        case InputStep.ORIGIN:
          return getDisplayName(this.originNetwork) || unspecified
        case InputStep.ASSET:
          return this.selectedToken.symbol || unspecified
        case InputStep.AMOUNT:
          return this.sendAmount && this.sendAmount !== 0
            ? `${this.sendAmount.toString()}`
            : unspecified
        case InputStep.REVIEW:
          return ''
        default:
          return unspecified
      }
    },
  },
  computed: {
    truncatedAddress(): string {
      const { address, connected } = this.store.state.wallet
      return connected ? truncateAddr(address) : ''
    },
    sendAmountBN(): string | undefined {
      if (!this.sendAmount || !this.selectedToken.symbol) return
      const parsedAmt = utils.parseUnits(
        `${this.sendAmount}`,
        this.selectedToken.decimals
      )
      if (parsedAmt.isZero()) return
      return parsedAmt.toString()
    },
  },
})
