import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full max-w-[600px] relative"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_nft_list = _resolveComponent("nft-list")!
  const _component_guide = _resolveComponent("guide")!

  return (_ctx.nfts)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.nfts.length && _ctx.allowed)
          ? (_openBlock(), _createBlock(_component_nft_list, { key: 0 }))
          : (_openBlock(), _createBlock(_component_guide, { key: 1 }))
      ]))
    : _createCommentVNode("", true)
}