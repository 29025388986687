
import { defineComponent } from 'vue'
import { getNetworkByDomainID } from '@/utils'
import { IndexerTx } from '@/utils/nomadAPI'

export default defineComponent({
  props: {
    tx: {
      type: Object as () => IndexerTx,
      required: true,
    },
  },
  methods: {
    getDisplayName(id: number): string {
      const network = getNetworkByDomainID(id)
      if (!network) return ''
      return network.displayName
    },
  },
})
