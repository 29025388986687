import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_App = _resolveComponent("App")!
  const _component_ErrorBoundary = _resolveComponent("ErrorBoundary")!
  const _component_n_notification_provider = _resolveComponent("n-notification-provider")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, {
    theme: _ctx.darkTheme,
    "theme-overrides": _ctx.themeOverrides
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_notification_provider, null, {
        default: _withCtx(() => [
          _createVNode(_component_ErrorBoundary, null, {
            default: _withCtx(() => [
              _createVNode(_component_App)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["theme", "theme-overrides"]))
}