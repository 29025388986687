import { TokenIdentifierMap, TokenMetadataMap } from '../types'

import wETHIcon from '@/assets/token-logos/WETH.svg'
import EvmosIcon from '@/assets/token-logos/EVMOS.svg'
import DEVIcon from '@/assets/token-logos/GLMR.svg'
import wADAIcon from '@/assets/token-logos/WADA.svg'
import WBTCIcon from '@/assets/token-logos/WBTC.svg'
import FRAXIcon from '@/assets/token-logos/FRAX.svg'
import FXSIcon from '@/assets/token-logos/FXS.svg'
import CQTIcon from '@/assets/token-logos/CQT.png'
import HBOTIcon from '@/assets/token-logos/HBOT.svg'
import SDLIcon from '@/assets/token-logos/SDL.svg'
import CARDSIcon from '@/assets/token-logos/CARDS.svg'
import C3Icon from '@/assets/token-logos/C3.svg'
import GEROIcon from '@/assets/token-logos/GERO.png'
import IAGIcon from '@/assets/token-logos/IAG.svg'
import USDCIcon from '@/assets/token-logos/USDC.svg'
import USDTIcon from '@/assets/token-logos/USDT.svg'
import DAIIcon from '@/assets/token-logos/DAI.svg'
import TICIcon from '@/assets/token-logos/TIC.svg'
import SBTCIcon from '@/assets/token-logos/SBTC.png'
import HBTCIcon from '@/assets/token-logos/HBTC.png'
import renBTCIcon from '@/assets/token-logos/renBTC.png'
import tBTCIcon from '@/assets/token-logos/tBTC.svg'
import wSTRIcon from '@/assets/token-logos/WSTR.svg'

import Thumbnail1 from '@/assets/nft-thumbnails/img-1.png'
import Thumbnail2 from '@/assets/nft-thumbnails/img-2.png'
import Thumbnail3 from '@/assets/nft-thumbnails/img-3.png'
import Thumbnail4 from '@/assets/nft-thumbnails/img-4.png'
import Thumbnail5 from '@/assets/nft-thumbnails/img-5.png'
import Thumbnail6 from '@/assets/nft-thumbnails/img-6.png'
import Thumbnail7 from '@/assets/nft-thumbnails/img-7.png'

// TODO: figure out what to do with these?
// {
// 	domain: 'ethereum',
// 	id: '0x3052ec861f09ff1c0a9b4f9e7373130872a67dee',
// 	name: 'WGLMR'
// },
// {
// 	domain: 'avalanche'
// 	id: '0x50b7545627a5162f82a992c33b87adc75187b218'
// 	name: 'WBTC'
// },
// {
// 	domain: 'ethereum',
// 	id: '0x7aafac9c63f70a946a75484bdac985ef0ba57bdb',
// 	name: 'USDC'
// },
// {
// 	domain: 'ethereum',
// 	id: '0x92c3a05b5cc7613e8a461968ad8616bae3c47178',
// 	name: 'WGLMR'
// },
// {
// 	domain: 'moonbeam',
// 	id: '0xcbb4825cf7cf72a88d1bddd494c1a251cf21b91f',
// 	name: 'WETH'
// },

// IMPORTANT make name same as tokens[token].symbol value
export const tokenIdentifiers: TokenIdentifierMap = {
  wETH: {
    domain: 'ethereum',
    id: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  },
  wEVMOS: {
    domain: 'evmos',
    id: '0xD4949664cD82660AaE99bEdc034a0deA8A0bd517',
  },
  wADA: {
    domain: 'milkomedaC1',
    id: '0xAE83571000aF4499798d1e3b0fA0070EB3A3E3F9',
  },
  wGLMR: {
    domain: 'moonbeam',
    id: '0xAcc15dC74880C9944775448304B263D191c6077F',
  },
  // {
  // 	domain: 'avalanche',
  // 	id: '0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7',
  // 	name: 'WAVAX'
  // },
  wBTC: {
    domain: 'ethereum',
    id: '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
  },
  USDC: {
    domain: 'ethereum',
    id: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  },
  USDT: {
    domain: 'ethereum',
    id: '0xdac17f958d2ee523a2206206994597c13d831ec7',
  },
  DAI: {
    domain: 'ethereum',
    id: '0x6b175474e89094c44da98b954eedeac495271d0f',
  },
  FRAX: {
    domain: 'ethereum',
    id: '0x853d955aCEf822Db058eb8505911ED77F175b99e',
  },
  FXS: {
    domain: 'ethereum',
    id: '0x3432B6A60D23Ca0dFCa7761B7ab56459D9C964D0',
  },
  SDL: {
    domain: 'ethereum',
    id: '0xf1Dc500FdE233A4055e25e5BbF516372BC4F6871',
  },
  CARDS: {
    domain: 'ethereum',
    id: '0x3d6f0dea3ac3c607b3998e6ce14b6350721752d9',
  },
  C3: {
    domain: 'ethereum',
    id: '0xf1a91c7d44768070f711c68f33a7ca25c8d30268',
  },
  GERO: {
    domain: 'ethereum',
    id: '0x3431f91b3a388115f00c5ba9fdb899851d005fb5',
  },
  IAG: {
    domain: 'ethereum',
    id: '0x40eb746dee876ac1e78697b7ca85142d178a1fc8',
  },
  CQT: {
    domain: 'ethereum',
    id: '0xD417144312DbF50465b1C641d016962017Ef6240',
  },
  HBOT: {
    domain: 'ethereum',
    id: '0xE5097D9baeAFB89f9bcB78C9290d545dB5f9e9CB',
  },
  TIC: {
    domain: 'avalanche',
    id: '0x75739a693459f33b1fbcc02099eea3ebcf150cbe',
  },
  sBTC: {
    domain: 'ethereum',
    id: '0xfe18be6b3bd88a2d2a7f928d00292e7a9963cfc6',
  },
  HBTC: {
    domain: 'ethereum',
    id: '0x0316eb71485b0ab14103307bf65a021042c6d380',
  },
  renBTC: {
    domain: 'ethereum',
    id: '0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
  },
  tBTC: {
    domain: 'ethereum',
    id: '0x18084fba666a33d37592fa2633fd49a74dd93a88',
  },
  wSTR: {
    domain: 'ethereum',
    id: '0xf0dc76c22139ab22618ddfb498be1283254612b1',
  },
  // {
  // 	domain: 'ethereum',
  // 	id: '0x0bf0d26a527384bcc4072a6e2bca3fc79e49fa2d'
  // 	name: 'MYT'
  // },
}

export const productionTokens: TokenMetadataMap = {
  wETH: {
    key: 'wETH',
    nativeNetwork: 'ethereum',
    symbol: 'wETH',
    name: 'Wrapped ETH',
    icon: wETHIcon,
    decimals: 18,
    coinGeckoId: 'weth',
    tokenIdentifier: tokenIdentifiers.wETH,
    affected: true,
    nftThumbnail: Thumbnail1,
  },
  wEVMOS: {
    key: 'wEVMOS',
    nativeNetwork: 'evmos',
    symbol: 'wEVMOS',
    name: 'Wrapped EVMOS',
    icon: EvmosIcon,
    decimals: 18,
    coinGeckoId: 'evmos',
    tokenIdentifier: tokenIdentifiers.wEVMOS,
    affected: false,
  },
  wGLMR: {
    key: 'wGLMR',
    nativeNetwork: 'moonbeam',
    symbol: 'wGLMR',
    name: 'Wrapped GLMR',
    icon: DEVIcon,
    decimals: 18,
    coinGeckoId: 'moonbeam',
    tokenIdentifier: tokenIdentifiers.wGLMR,
    affected: false,
  },
  wADA: {
    key: 'wADA',
    nativeNetwork: 'milkomedaC1',
    symbol: 'wADA',
    name: 'Wrapped milkADA',
    icon: wADAIcon,
    decimals: 18,
    coinGeckoId: 'cardano',
    tokenIdentifier: tokenIdentifiers.wADA,
    affected: false,
  },
  WBTC: {
    key: 'WBTC',
    nativeNetwork: 'ethereum',
    symbol: 'WBTC',
    name: 'Wrapped BTC',
    icon: WBTCIcon,
    decimals: 8,
    coinGeckoId: 'wrapped-bitcoin',
    tokenIdentifier: tokenIdentifiers.wBTC,
    affected: true,
    nftThumbnail: Thumbnail1,
  },
  USDC: {
    key: 'USDC',
    nativeNetwork: 'ethereum',
    symbol: 'USDC',
    name: 'USDC',
    icon: USDCIcon,
    decimals: 6,
    coinGeckoId: 'usd-coin',
    tokenIdentifier: tokenIdentifiers.USDC,
    affected: true,
    nftThumbnail: Thumbnail2,
  },
  USDT: {
    key: 'USDT',
    nativeNetwork: 'ethereum',
    symbol: 'USDT',
    name: 'USDT',
    icon: USDTIcon,
    decimals: 6,
    coinGeckoId: 'tether',
    tokenIdentifier: tokenIdentifiers.USDT,
    affected: true,
    nftThumbnail: Thumbnail2,
  },
  DAI: {
    key: 'DAI',
    nativeNetwork: 'ethereum',
    symbol: 'DAI',
    name: 'DAI',
    icon: DAIIcon,
    decimals: 18,
    coinGeckoId: 'dai',
    tokenIdentifier: tokenIdentifiers.DAI,
    affected: true,
    nftThumbnail: Thumbnail3,
  },
  FRAX: {
    key: 'FRAX',
    nativeNetwork: 'ethereum',
    symbol: 'FRAX',
    name: 'FRAX',
    icon: FRAXIcon,
    decimals: 18,
    coinGeckoId: 'frax',
    tokenIdentifier: tokenIdentifiers.FRAX,
    affected: true,
    nftThumbnail: Thumbnail3,
  },
  FXS: {
    key: 'FXS',
    nativeNetwork: 'ethereum',
    symbol: 'FXS',
    name: 'FXS',
    icon: FXSIcon,
    decimals: 18,
    coinGeckoId: 'frax-share',
    tokenIdentifier: tokenIdentifiers.FXS,
    affected: true,
    nftThumbnail: Thumbnail4,
  },
  HBOT: {
    key: 'HBOT',
    nativeNetwork: 'ethereum',
    symbol: 'HBOT',
    name: 'Hummingbot',
    icon: HBOTIcon,
    decimals: 18,
    coinGeckoId: 'hummingbot',
    tokenIdentifier: tokenIdentifiers.HBOT,
    affected: true,
    nftThumbnail: Thumbnail7,
  },
  SDL: {
    key: 'SDL',
    nativeNetwork: 'ethereum',
    symbol: 'SDL',
    name: 'Saddle DAO',
    icon: SDLIcon,
    decimals: 18,
    coinGeckoId: 'saddle-finance',
    tokenIdentifier: tokenIdentifiers.SDL,
    affected: true,
    nftThumbnail: Thumbnail7,
  },
  CQT: {
    key: 'CQT',
    nativeNetwork: 'ethereum',
    symbol: 'CQT',
    name: 'CQT',
    icon: CQTIcon,
    decimals: 18,
    coinGeckoId: 'covalent',
    tokenIdentifier: tokenIdentifiers.CQT,
    affected: true,
    nftThumbnail: Thumbnail6,
  },
  CARDS: {
    key: 'CARDS',
    nativeNetwork: 'ethereum',
    symbol: 'CARDS',
    name: 'Card Starter',
    icon: CARDSIcon,
    decimals: 18,
    coinGeckoId: 'cardstarter',
    tokenIdentifier: tokenIdentifiers.CARDS,
    affected: true,
    nftThumbnail: Thumbnail5,
  },
  C3: {
    key: 'C3',
    nativeNetwork: 'ethereum',
    symbol: 'C3',
    name: 'CHARLI3',
    icon: C3Icon,
    decimals: 18,
    coinGeckoId: 'charli3',
    tokenIdentifier: tokenIdentifiers.C3,
    affected: true,
    nftThumbnail: Thumbnail5,
  },
  GERO: {
    key: 'GERO',
    nativeNetwork: 'ethereum',
    symbol: 'GERO',
    name: 'GeroWallet',
    icon: GEROIcon,
    decimals: 18,
    coinGeckoId: 'gerowallet',
    tokenIdentifier: tokenIdentifiers.GERO,
    affected: true,
    nftThumbnail: Thumbnail4,
  },
  IAG: {
    key: 'IAG',
    nativeNetwork: 'ethereum',
    symbol: 'IAG',
    name: 'Iagon',
    icon: IAGIcon,
    decimals: 18,
    coinGeckoId: 'iagon',
    tokenIdentifier: tokenIdentifiers.IAG,
    affected: true,
    nftThumbnail: Thumbnail6,
  },
  TIC: {
    key: 'TIC',
    nativeNetwork: 'avalanche',
    symbol: 'TIC',
    name: 'ElasticSwap',
    icon: TICIcon,
    decimals: 18,
    coinGeckoId: 'elasticswap',
    tokenIdentifier: tokenIdentifiers.TIC,
    affected: false,
  },
  sBTC: {
    key: 'sBTC',
    nativeNetwork: 'ethereum',
    symbol: 'SBTC',
    name: 'sBTC',
    icon: SBTCIcon,
    decimals: 18,
    coinGeckoId: 'sbtc',
    tokenIdentifier: tokenIdentifiers.sBTC,
    affected: false,
  },
  HBTC: {
    key: 'HBTC',
    nativeNetwork: 'ethereum',
    symbol: 'HBTC',
    name: 'Huobi BTC',
    icon: HBTCIcon,
    decimals: 18,
    coinGeckoId: 'huobi-btc',
    tokenIdentifier: tokenIdentifiers.HBTC,
    affected: false,
  },
  renBTC: {
    key: 'renBTC',
    nativeNetwork: 'ethereum',
    symbol: 'RENBTC',
    name: 'renBTC',
    icon: renBTCIcon,
    decimals: 8,
    coinGeckoId: 'renbtc',
    tokenIdentifier: tokenIdentifiers.renBTC,
    affected: false,
  },
  tBTC: {
    key: 'tBTC',
    nativeNetwork: 'ethereum',
    symbol: 'TBTC',
    name: 'tBTC',
    icon: tBTCIcon,
    decimals: 18,
    coinGeckoId: 'tbtc',
    tokenIdentifier: tokenIdentifiers.tBTC,
    affected: false,
  },
  wSTR: {
    key: 'wSTR',
    nativeNetwork: 'ethereum',
    symbol: 'WSTR',
    name: 'Wrapped Star',
    icon: wSTRIcon,
    decimals: 18,
    coinGeckoId: 'wrapped-star',
    tokenIdentifier: tokenIdentifiers.wSTR,
    affected: false,
  },
}
