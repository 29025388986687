
import { defineComponent, computed } from 'vue'
import { NSpin } from 'naive-ui'
import { useStore } from '@/store'
import { networksArr } from '@/config'
import NomadButton from '@/components/Button.vue'
import { isNetworkName, truncateAddr } from '@/utils'

export default defineComponent({
  components: { NomadButton, NSpin },
  setup: () => {
    const store = useStore()

    return {
      originNetwork: computed(() => store.state.userInput.originNetwork),
      walletAddr: computed(() => store.state.wallet.address),
      connected: computed(() => store.state.wallet.connected),
      store,
    }
  },
  data: () => ({
    title: 'Select an origin chain',
    subtitle: 'Where would you like to bridge funds from?',
    networks: networksArr,
    loading: false,
  }),
  computed: {
    truncatedAddress(): string {
      return this.connected ? truncateAddr(this.walletAddr) : ''
    },
    valid(): boolean {
      if (!this.originNetwork) return false
      return isNetworkName(this.originNetwork)
    },
  },
  methods: {
    async switchNetwork(networkName: string) {
      await this.store.dispatch('switchNetwork', networkName)
      this.store.dispatch('setOriginNetwork', networkName)
    },
    async next() {
      this.loading = true
      await this.store.dispatch('getBalances')
      this.$emit('next')
      this.loading = false
    },
  },
})
