
import { defineComponent } from 'vue'
import { NIcon } from 'naive-ui'
import { TimeOutline, ArrowRedo, CheckmarkOutline } from '@vicons/ionicons5'
import CopyHash from '@/components/CopyHash.vue'
import { getNetworkByDomainID } from '@/utils'
import { getStatus, TxStatus, IndexerTx } from '@/utils/nomadAPI'

export default defineComponent({
  props: {
    tx: {
      type: Object as () => IndexerTx,
      required: true,
    },
  },
  components: {
    NIcon,
    CopyHash,
    TimeOutline,
    ArrowRedo,
    CheckmarkOutline,
  },
  data() {
    return {
      TxStatus,
    }
  },
  computed: {
    manualProcess(): boolean {
      // assume false if no destination domain is available
      if (!this.tx.destination_domain_id) return false
      const dest = getNetworkByDomainID(this.tx.destination_domain_id)
      return dest.manualProcessing
    },
    status() {
      return getStatus(this.tx)
    },
  },
})
