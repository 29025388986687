/**
 * UserInput Module contains the user-inputed information gathered for
 * submission. Rather than dealing with emits and props between many components
 * handling them in the store makes it easier to deal with data changes
 */
import { MutationTree, ActionTree, GetterTree } from 'vuex'
import { RootState } from '@/store'
import * as types from '@/store/mutation-types'
import { BigNumber } from 'ethers'
import { tokensArr } from '@/config'
import { TokenMetadata, NetworkName } from '@/config/types'
import { nullToken } from '@/utils'
// import { User } from '@sentry/vue'

export enum InputStep {
  WALLET,
  ORIGIN,
  ASSET,
  AMOUNT,
  REVIEW,
  SENDING,
}

export interface UserInputState {
  step: InputStep
  originNetwork: NetworkName | ''
  token: TokenMetadata
  sendAmount: number
  destinationNetwork: NetworkName | ''
}

const state: UserInputState = {
  step: InputStep.ORIGIN,
  originNetwork: '',
  token: nullToken,
  sendAmount: 0,
  destinationNetwork: '',
}

const mutations = <MutationTree<UserInputState>>{
  [types.SET_INPUT_STEP](state: UserInputState, step: InputStep) {
    console.log('{dispatch} set input step: ', step)
    state.step = step
  },

  [types.SET_ORIGIN_NETWORK](state: UserInputState, network: NetworkName | '') {
    console.log('{dispatch} set origin network: ', network)
    state.originNetwork = network
  },

  [types.SET_TOKEN](state: UserInputState, token: TokenMetadata) {
    console.log('{dispatch} set token: ', token)
    state.token = token
  },

  // Amount comes in as a number. We should store it as a number
  [types.SET_SEND_AMOUNT](state: UserInputState, amount: number) {
    console.log('{dispatch} set send amount: ', amount)
    state.sendAmount = amount
  },

  [types.SET_DESTINATION_NETWORK](
    state: UserInputState,
    network: NetworkName | ''
  ) {
    console.log('{dispatch} set destination network: ', network)
    state.destinationNetwork = network
  },
}

const actions = <ActionTree<UserInputState, RootState>>{
  setStep({ commit }, step: InputStep) {
    commit(types.SET_INPUT_STEP, step)
  },

  nextStep({ commit, state }) {
    commit(types.SET_INPUT_STEP, state.step + 1)
  },

  prevStep({ commit, state }) {
    commit(types.SET_INPUT_STEP, state.step - 1)
  },

  setOriginNetwork({ commit }, network: NetworkName) {
    commit(types.SET_ORIGIN_NETWORK, network)
  },

  setToken({ commit }, token: TokenMetadata) {
    commit(types.SET_TOKEN, token)
  },

  setSendAmount({ commit }, amount: string) {
    commit(types.SET_SEND_AMOUNT, Number.parseFloat(amount))
  },

  setDestinationNetwork({ commit }, network: string) {
    commit(types.SET_DESTINATION_NETWORK, network)
  },

  clearDestinationNetwork({ commit }) {
    commit(types.SET_DESTINATION_NETWORK, '')
  },

  clearInputs({ commit, rootState }) {
    console.log('resetting user input')
    commit(types.SET_ORIGIN_NETWORK, rootState.wallet.network)
    commit(types.SET_DESTINATION_NETWORK, '')
    commit(types.SET_SEND_AMOUNT, 0)
    commit(types.SET_TOKEN, nullToken)
    commit(types.SET_INPUT_STEP, InputStep.ORIGIN)
  },
}

const getters = <GetterTree<UserInputState, RootState>>{
  // Transforms amount into BigNumber for comparison to balance
  getSendAmountAsBN(state: UserInputState) {
    return BigNumber.from(state.sendAmount)
  },
  availableTokens(state: UserInputState) {
    return tokensArr.filter(
      (t: TokenMetadata) => t.tokenIdentifier.domain !== state.originNetwork
    )
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
