import { productionTokens } from './production'
import { developmentTokens } from './development'

const env = process.env.VUE_APP_NOMAD_ENVIRONMENT

const tokensByEnv = {
  production: productionTokens,
  development: developmentTokens,
}

export const tokensList = tokensByEnv[env]
