import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-337d1e3e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex flex-row" }
const _hoisted_2 = { class: "flex flex-col" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkmark_outline = _resolveComponent("checkmark-outline")!
  const _component_arrow_redo = _resolveComponent("arrow-redo")!
  const _component_time_outline = _resolveComponent("time-outline")!
  const _component_n_icon = _resolveComponent("n-icon")!
  const _component_copy_hash = _resolveComponent("copy-hash")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["loader-bg flex justify-center items-center mr-2", {
        orange: _ctx.manualProcess && _ctx.status < _ctx.TxStatus.PROCESSED,
        green: _ctx.status === _ctx.TxStatus.PROCESSED,
      }])
    }, [
      _createVNode(_component_n_icon, {
        class: "cursor-pointer opacity-70",
        size: "25"
      }, {
        default: _withCtx(() => [
          (_ctx.status === _ctx.TxStatus.PROCESSED)
            ? (_openBlock(), _createBlock(_component_checkmark_outline, { key: 0 }))
            : (_ctx.manualProcess)
              ? (_openBlock(), _createBlock(_component_arrow_redo, {
                  key: 1,
                  class: "click-me"
                }))
              : (_openBlock(), _createBlock(_component_time_outline, { key: 2 }))
        ]),
        _: 1
      })
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_copy_hash, {
        address: _ctx.tx.dispatch_tx
      }, null, 8, ["address"]),
      (_ctx.status === _ctx.TxStatus.PROCESSED)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Complete"))
        : (_ctx.manualProcess)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, "Action required"))
          : (_openBlock(), _createElementBlock("div", _hoisted_5, "Pending"))
    ])
  ]))
}